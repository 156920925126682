import React, { useState } from "react";
import { useFilter } from "react-aria";
import Drawer from "rc-drawer";
import styles from "./freelancers-tab-send-request-drawer.module.scss";
import { SendRequestDrawerFooter } from "../SendRequestDrawerFooter";
import { RequestDetailsCard } from "../RequestDetailsCard";
import { SendRequestDrawerHead } from "../SendRequestDrawerHead";
import useGetRequests from "modules/freelancers/api/useGetActiveRequests";
import toaster from "common/Toaster";
import { useSendRequest } from "modules/freelancers/api/useSendEmailRequest";
import { queryCache } from "react-query";
import { GET_FREELANCERS_QUERY_KEY } from "../../hooks/useGetFreelancersList";

const FreelancersTabSendRequestDrawer = ({
	onClose,
	onSendSuccess = () => {},
	freelancersIds = [],
	freelancerFirstName,
	profileId = ""
}) => {
	const [displayButtonNewRequest, setDisplayButtonNewRequest] = useState(false);
	const [request, setRequest] = useState();
	const { data: requestsList } = useGetRequests({
		profile_id: profileId
	});
	const [sendRequestEmail] = useSendRequest({
		onSuccess: () => {
			onClose();
			queryCache.invalidateQueries(GET_FREELANCERS_QUERY_KEY);
			toaster.success("Your request has successfully been sent.");
			onSendSuccess();
		},
		onError: error => {
			toaster.danger(error.detail);
		}
	});

	let { contains } = useFilter({
		sensitivity: "base"
	});

	const onSend = () => {
		sendRequestEmail({
			job_id: request.value,
			profile_ids: freelancersIds
		});
	};

	const getRequestById = id => {
		return requestsList.find(({ _id }) => _id === id);
	};

	let onRequestSelectionChange = key => {
		const value = {
			label: getRequestById(key)?.title ?? "",
			value: key
		};

		if (key) {
			setDisplayButtonNewRequest(false);
		}

		setRequest(value);
	};

	let onRequestInputChange = value => {
		const valueObject = {
			label: value,
			value: value === "" ? null : request?.value
		};

		const list = requestsList.map(({ title }) => title);
		let filteredList = list.filter(composer => contains(composer, value));

		if (filteredList.length === 0) {
			setDisplayButtonNewRequest(true);
		} else {
			setDisplayButtonNewRequest(false);
		}

		setRequest(valueObject);
	};

	const getRequestDetails = () => {
		return requestsList?.find(({ _id }) => _id === request?.value);
	};

	return (
		<Drawer
			open
			width="810px"
			height="100%"
			placement="right"
			style={{ zIndex: 1000, backgroundColor: "transparent" }}
			level={"root"}
			maskClosable={true}
			onClose={onClose}
		>
			<div className={styles.container}>
				<div className={styles.root}>
					<SendRequestDrawerHead
						title={
							<div className={styles.title}>
								Send request{" "}
								<span className={styles.count}>
									({freelancersIds.length}{" "}
									{freelancersIds.length === 1 ? "recipient" : "recipients"})
								</span>
							</div>
						}
						onClose={onClose}
					/>
					<div className={styles.body}>
						<RequestDetailsCard
							onRequestInputChange={onRequestInputChange}
							requestsList={requestsList || []}
							onRequestSelectionChange={onRequestSelectionChange}
							request={getRequestDetails()}
							displayButtonNewRequest={displayButtonNewRequest}
							freelancerFirstName={freelancerFirstName}
						/>
					</div>
					<SendRequestDrawerFooter
						sendDisabled={!request?.value}
						onSend={onSend}
						onClose={onClose}
					/>
				</div>
			</div>
		</Drawer>
	);
};

export default FreelancersTabSendRequestDrawer;
