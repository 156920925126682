import React from "react";
import styles from "./calendar-timezone.module.scss";
import { Controller, useFormContext } from "react-hook-form";
import { ComboBox } from "common/ComboBox";
import { Item } from "react-stately";
import Skeleton from "react-loading-skeleton";
import FieldError from "common/MessagingToolNewEmail/components/FieldError";

const CalendarTimezone = ({ isLoading, timezones }) => {
	const {
		control,
		watch,
		formState: { errors }
	} = useFormContext();

	const onSelectionTimezoneChange = (key, onChange) => {
		const value = {
			label: timezones.find(item => item.value === key)?.label || "",
			value: key
		};

		onChange(value);
	};

	const onInputChange = (value, onChange) => {
		const valueObject = {
			label: value,
			value: value === "" ? null : watch("timezone").value
		};
		onChange(valueObject);
	};

	if (isLoading) return <Skeleton height={44} width={243} />;
	return (
		<>
			<Controller
				name="timezone"
				control={control}
				render={({ field: { onChange, value } }) => {
					return (
						<ComboBox
							optionsWrapperClassName={styles.optionsWrapper}
							inputRootClassName={styles.combobox}
							inputValue={value?.label}
							selectedKey={value?.value}
							placeholder={"Select a timezone"}
							onChange={onChange}
							onInputChange={value => onInputChange(value, onChange)}
							onSelectionChange={value =>
								onSelectionTimezoneChange(value, onChange)
							}
						>
							{timezones.map(option => {
								return <Item key={option.value}>{option.label}</Item>;
							})}
						</ComboBox>
					);
				}}
			/>
			<FieldError error={errors?.timezone} className={styles.error} />
		</>
	);
};

export default CalendarTimezone;
