//TODO Replace function findColumn instead get column in other modules
import produce from "immer";
import flatMapDeep from "lodash/flatMapDeep";
import { dateToTimestamp } from "config/helpers";
import { formatDateToCalendarObject } from "config/helpers";
import get from "lodash/get";
import _isObject from "lodash/isObject";
import _isEmpty from "lodash/isEmpty";

export const OPERATORS = {
	is: { value: "is", label: "is" },
	isNot: { value: "is_not", label: "is not" },
	gt: { value: "gt", label: "is greater than" },
	between: { value: "range", label: "between" },
	contains: { value: "contains", label: "contains" },
	notContains: { value: "not_contains", label: "does not contain" },
	anyOf: { value: "is_any", label: "is any of" },
	isEmpty: { value: "is_empty", label: "is empty" },
	before: { value: "before", label: "before" },
	after: { value: "after", label: "after" },
	eq: { value: "is", label: "equals" },
	lessThan: { value: "<", label: "is less than" },
	greaterThan: { value: ">", label: "is greater than" },
	startWith: { value: "begins_with", label: "starts with" },
	lessThanOrEqual: { value: "<=", label: "is less than or equal" },
	greaterThanOrEqual: { value: ">=", label: "is greater than or equal" }
};

export const LOGICAL_OPERATORS = {
	and: { label: "and", value: "and" },
	or: { label: "or", value: "or" }
};

export const getComponentByOperator = (type, operator, componentByOperator) => {
	const node = componentByOperator[type];
	if (node && node[operator]) {
		return node[operator];
	} else {
		return node.default;
	}
};

export const updateMap = (mapParam, id, _object) => {
	const innerObject = mapParam.get(id);

	const newObject = {
		...innerObject,
		..._object
	};

	const newMap = produce(mapParam, draft => {
		draft.set(id, newObject);
	});

	return newMap;
};

export const findColumn = (id, columns) => {
	const fields = flatMapDeep(columns, el => {
		return el.children ? el.children : el;
	});

	return fields.find(({ name }) => name === id);
};

export const findFilterPayload = (id, columns) => {
	const { payload } = findColumn(id, columns);
	return payload;
};

export const serializeFilters = data => {
	const valueToStore = new Map();

	for (const [key, value] of data.state.state) {
		if (key === "FREELANCERS_FILTER_ID") {
			const newFilter = value.filters.map(
				({ id, label, name, operator, type, value }) => {
					let newValue = value;
					let valueType = "generic";
					if (value[0]?.place) {
						valueType = "address";
						newValue = formatTypeLocation({ value }, true);
					} else if (value?.start && value?.end) {
						valueType = "date";
						newValue = [
							dateToTimestamp(
								`${value.start.day}-${value.start.month}-${value.start.year}`
							),
							dateToTimestamp(
								`${value.end.day}-${value.end.month}-${value.end.year}`
							)
						];
					} else if (value?.day && value?.month && value?.year) {
						valueType = "date";
						newValue = [
							dateToTimestamp(`${value.day}-${value.month}-${value.year}`)
						];
					}
					return {
						id,
						label,
						name,
						operator,
						type,
						value: newValue,
						valueType
					};
				}
			);
			valueToStore.set(key, {
				query: value.query,
				filters: newFilter,
				offset: value.offset,
				sortBy: value.sortBy,
				limit: value.limit
			});
		}
	}

	return JSON.stringify(Array.from(valueToStore.entries()));
};

export const deserializeFilters = async value => {
	const data = JSON.parse(value);
	const state = new Map();

	for (const [key, value] of data) {
		state.set(key, {
			...value,
			filters: await Promise.all(
				value.filters.map(async ({ valueType, value, ...rest }) => {
					let newValue;

					if (value.viewport) {
						newValue = await formatLocation(value);
					} else if (valueType === "date" && value?.length === 2) {
						newValue = {
							start: formatDateToCalendarObject(value[0]),
							end: formatDateToCalendarObject(value[1])
						};
					} else if (valueType === "date" && value.length === 1) {
						newValue = formatDateToCalendarObject(value[0]);
					} else {
						newValue = value;
					}

					return {
						...rest,
						value: newValue
					};
				})
			)
		});
	}

	return {
		state: {
			state,
			isHydrationCompleted: true
		}
	};
};

export const formatLocation = async value => {
	let searchObj = {};
	if ("place_id" in value) {
		searchObj = { placeId: value.place_id };
	} else {
		searchObj = {
			location: { lat: value.latitude, lng: value.longitude }
		};
	}
	const location = await getLocation(searchObj);

	return [
		{
			place: {
				...location,
				description: renderLocationValues(value)
			},
			radius: get(value, "distance", 0)
		}
	];
};

const renderLocationValues = location => {
	if (!location) {
		return "";
	}
	if (get(location, "description", "") !== "") {
		return get(location, "description");
	}
	return `${get(location, "number", "") && get(location, "number") + " "}${get(
		location,
		"box",
		""
	) && get(location, "box", "") + " "}${get(location, "street", "") &&
		get(location, "street", "") + " "}${get(location, "city", "") &&
		get(location, "city", "") + " "}${get(location, "country", "") &&
		get(location, "country", "") + " "}${get(location, "zip")}`;
};

const getLocation = searchObj => {
	const geocoder = new window.google.maps.Geocoder();
	return new Promise(resolve => {
		geocoder.geocode(searchObj).then(response => {
			resolve(get(response, "results[0]"));
		});
	});
};

export const formatTypeLocation = (filter, forSearch = false) => {
	const { value } = filter;
	const newValue = value || [];

	const valuesFormatted = newValue.map(({ place, radius }) => {
		const address = {};

		place.address_components.map(elem => {
			if (elem.types[0] === "country") {
				address[elem.types[0]] = elem.long_name;
				address.iso_country = elem.short_name;
				return;
			}
			return (address[elem.types[0]] = elem.long_name);
		});

		address.latitude = place.geometry.location.lat();
		address.longitude = place.geometry.location.lng();
		address.street =
			address.route ||
			address.neighborhood ||
			address.premise ||
			address.sublocality_level_1 ||
			address.sublocality_level_2 ||
			address.sublocality_level_3 ||
			address.sublocality_level_4 ||
			address.sublocality_level_5 ||
			address.subpremise ||
			address.sublocality ||
			address.jpns ||
			"";
		address.country = address.country || "";
		address.is_main = false;
		address.zip = address.postal_code || "";
		address.latitude = address.latitude || "";
		address.longitude = address.longitude || "";
		address.city =
			address.locality ||
			address.administrative_area_level_1 ||
			address.administrative_area_level_2 ||
			address.administrative_area_level_3 ||
			address.administrative_area_level_4 ||
			address.administrative_area_level_5 ||
			"";
		address.number = address.street_number || "";
		address.box = address.box || "";
		address.iso_country = address.iso_country || "";

		const values = place?.geometry?.viewport;
		let viewport = null;

		if (values) {
			viewport = {
				northeast: {
					lat: values.getNorthEast().lat(),
					lng: values.getNorthEast().lng()
				},
				southwest: {
					lat: values.getSouthWest().lat(),
					lng: values.getSouthWest().lng()
				}
			};
		}

		const typesSupportedAutoComplete = [
			"locality",
			"sublocality",
			"postal_code",
			"country",
			"administrative_area_level_1",
			"administrative_area_level_2",
			"administrative_area_level_3",
			"locality",
			"political"
		];

		let isSendViewport = false;

		typesSupportedAutoComplete.forEach(type => {
			if (place.types.includes(type)) isSendViewport = true;
		});

		const res = {
			zip: address.zip,
			country: address.country,
			number: address.number,
			iso_country: address.iso_country,
			city: address.city,
			street: address.street,
			latitude: address.latitude,
			is_main: address.is_main,
			box: address.box,
			longitude: address.longitude,
			distance_unit: "km",
			distance: parseInt(radius),
			viewport: isSendViewport ? viewport : [],
			place_id: forSearch ? place.place_id : undefined
		};

		return res;
	});

	return valuesFormatted.length ? valuesFormatted[0] : null;
};

export const isFilterEmpty = value => {
	return (
		value === null ||
		value === "" ||
		value === undefined ||
		(_isObject(value) && _isEmpty(value))
	);
};

export const isAllFiltersEmpty = list => {
	return list.every(({ value }) => isFilterEmpty(value));
};

export function getValidFilters(filters) {
	return filters.filter(({ value }) => !isFilterEmpty(value));
}
