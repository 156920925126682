import { utcTimeStamp } from "config/helpers";
export const getSearchMissionsListPayload = ({
	filters,
	logicalOperator,
	offset,
	sortBy,
	limit,
	query
}) => {
	return {
		op: logicalOperator,
		fields: formatFilters(filters),
		search: query,
		offset,
		sort_by: sortBy,
		limit
	};
};

export const formatFilters = filters => {
	const definedFilters = filters.filter(({ value }) => {
		// Be careful about false value, filtering a filter with false value is a bug
		if (
			[undefined, null, ""].includes(value) ||
			(Array.isArray(value) && value.length === 0) ||
			(value !== null &&
				typeof value === "object" &&
				Object.keys(value).length === 0)
		) {
			return false;
		} else {
			return true;
		}
	});

	const filtersFormatted = definedFilters.map(filter => {
		const { name, operator } = filter;

		let filterFormatted;
		if (name === "start_date" || name === "end_date") {
			filterFormatted = formatFilterTypeDate(filter, name);
		} else if (
			name === "progress" ||
			name === "extensions" ||
			name === "rate" ||
			name === "cost" ||
			name === "selling_price"
		) {
			const shouldFormatValue = ["rate", "cost", "selling_price"].includes(
				name
			);
			const value = shouldFormatValue
				? formatNumber(filter.value)
				: filter?.value;

			filterFormatted = {
				value,
				field: name,
				op: operator
			};
		} else if (
			name === "mission_reference" ||
			name === "mission_title" ||
			name === "consultant" ||
			name === "onboarded" ||
			name === "external_manager" ||
			name === "accounting_manager" ||
			name === "po_number"
		) {
			let field = name;
			filterFormatted = {
				value: filter.value,
				field,
				op: operator
			};
		} else {
			if (name) {
				filterFormatted = formatFilter(filter);
			}
		}

		return filterFormatted;
	});

	return filtersFormatted;
};

const formatNumber = value => {
	return (value *= 100);
};

const formatFilterTypeDate = (filter, field) => {
	const { value, operator, name } = filter;

	if (!value) {
		return {
			field: field || name,
			value: null,
			op: operator
		};
	}
	let valueFormatted;

	if (!value.start) {
		const startDate = utcTimeStamp({
			date: convertCalendarDateToMS(value)
		});

		const endDate = utcTimeStamp({
			date: convertCalendarDateToMS(value),
			isStart: false
		});
		valueFormatted = [startDate, endDate];
	} else {
		const startDate = utcTimeStamp({
			date: convertCalendarDateToMS(value.start)
		});

		const endDate = utcTimeStamp({
			date: convertCalendarDateToMS(value.end),
			isStart: false
		});

		valueFormatted = [startDate, endDate];
	}

	return {
		field: field || name,
		value: valueFormatted,
		op: operator
	};
};
const formatFilter = filter => {
	const { name, value, operator } = filter;
	const ids = reduceItems(value || []).map(({ id }) => id);

	return {
		field: name,
		value: ids,
		op: operator
	};
};

export const formatBooleanFilter = ({ name, operator, value }) => {
	return {
		field: name === "been_on_mission" ? "on_mission" : name,
		op: operator,
		value: value
	};
};

function reduceItems(items) {
	return items.reduce((acc, item) => [...acc, ...item.children], []);
}

const convertCalendarDateToMS = date => {
	const { year, month, day } = date;
	var dateObject = new Date(`${year}/${month}/${day}`);

	return dateObject;
};
