import React from "react";
import styles from "./company-culture-card.module.scss";
import { ReactComponent as WarningIcon } from "static/icons/alert-triangle-sm.svg";

const CompanyCultureCard = () => {
	return (
		<div className={styles.container}>
			<div className={styles.infoIcon}>
				<WarningIcon />
			</div>
			<div className={styles.description}>
				Add at least one company culture & value to save. <br />
				This will help your company stand out and appear higher in search
				results.
			</div>
		</div>
	);
};

export default CompanyCultureCard;
