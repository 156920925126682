import create from "zustand";

export const useVacancyFilesStore = create(set => ({
	vacancyId: null,
	setVacancyId: value => set({ vacancyId: value }),

	vacancyFiles: {},
	setVacancyFiles: values => set({ vacancyFiles: values }),

	clearVacancyFilesStore: () => set({ vacancyFiles: {}, vacancyId: null })
}));
