import React, { useMemo } from "react";
import { ReactComponent as StatusIcon } from "static/icons/status-icon.svg";
import { ReactComponent as PriorityIcon } from "static/icons/priority-icon.svg";
import { ReactComponent as UserIcon } from "static/icons/user-icon.svg";
import { ReactComponent as CreationDateIcon } from "static/icons/creation-date-icon.svg";
import {
	VALUE_OPERATORS,
	DATE_OPERATORS,
	USER,
	USER_ID,
	CREATION_DATE,
	INTERACTIONS,
	VACANCY,
	INTERACTIONS_FILTER
} from "config";
import { v4 as uuid } from "uuid";
import { INTERACTIONS_FILTER_SETTINGS } from "config/api-endpoints";
import { client } from "lib/api-client";
import { CREATED_BY, STATUS } from "config";
import SkeletonComponent from "modules/candidate/Profile/components/Skeleton/Skeleton";
import get from "lodash/get";
import Tooltip from "common/Tippy";
import RenderUser from "modules/candidate/Profile/Pages/ProfileInteractions/renderUser";
import Date from "modules/candidate/Profile/Pages/ProfileInteractions/date";
import Status from "modules/candidate/Profile/Pages/ProfileInteractions/status";
import classnames from "modules/candidate/Profile/Pages/ProfileInteractions/interactions.module.scss";

const getFilterSettings = (tag, id) => {
	return client(INTERACTIONS_FILTER_SETTINGS, {
		body: {
			id: id,
			list: "interactions",
			tag
		}
	});
};

export const sortBylist = [
	{
		field: INTERACTIONS,
		icon: <StatusIcon />,
		label: "Status",
		options: []
	},
	{
		field: VACANCY,
		icon: <PriorityIcon />,
		label: "Vacancy"
	},
	{
		field: USER,
		icon: <UserIcon />,
		label: "User",
		options: []
	},
	{
		field: CREATION_DATE,
		icon: <CreationDateIcon />,
		label: "Date",
		options: []
	}
];

export const filterList = id => [
	{
		field: INTERACTIONS,
		icon: <StatusIcon />,
		label: "Status",
		options: [],
		filterOptions: () => getFilterSettings(INTERACTIONS_FILTER, id),
		selectedOption: "",
		oprators: VALUE_OPERATORS,
		isMultipleSelect: true,
		id: uuid()
	},
	{
		field: USER_ID,
		icon: <UserIcon />,
		label: "User",
		options: [],
		filterOptions: () => getFilterSettings(USER, id),
		selectedOption: "",
		oprators: VALUE_OPERATORS,
		isMultipleSelect: true,
		id: uuid()
	},
	{
		field: CREATION_DATE,
		icon: <CreationDateIcon />,
		label: "Date",
		options: [],
		selectedOption: "",
		oprators: DATE_OPERATORS,
		isMultipleSelect: false,
		id: uuid()
	}
];

export const columns = sortedField =>
	useMemo(
		() => [
			{
				Header: "User",
				accessor: "created_by",
				isSorted: sortedField === CREATED_BY
			},
			{
				Header: "Date",
				accessor: "creation_date",
				isSorted: sortedField === CREATION_DATE
			},
			{
				Header: "Vacancy",
				accessor: "title",
				isSorted: sortedField === VACANCY
			},
			{
				Header: "Interaction",
				accessor: "status",
				isSorted: sortedField === STATUS
			}
		],
		[sortedField]
	);

export const dataSkeleton = new Array(9).fill({
	title: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	status: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	user: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	creation_date: <SkeletonComponent width={60} height={14} borderRadius={10} />
});

export const getData = (isFetching, resolvedData, user) =>
	useMemo(
		() =>
			isFetching
				? dataSkeleton
				: get(resolvedData, "data", []).map(item => {
						const fullName =
							user.first_name !== get(item, "user_action.first_name", "") &&
							user.last_name !== get(item, "user_action.last_name", "")
								? get(item, "user_action.first_name", "") +
								  " " +
								  get(item, "user_action.last_name", "")
								: "You";
						const status = get(item, "history_type", "");
						const title = get(item, "job.title", "");
						const renderedTitle = (
							<Tooltip content={title} theme="dark" overflow="hidden">
								<span className={classnames.title}>{title}</span>
							</Tooltip>
						);

						return {
							created_by: <RenderUser name={fullName} />,
							title: renderedTitle,
							status: <Status status={status} />,
							creation_date: <Date date={get(item, "created_at", "")} />
						};
				  }),
		[isFetching, resolvedData, user]
	);
