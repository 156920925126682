import React from "react";
import cx from "classnames";
import get from "lodash/get";

import styles from "./accordion.module.scss";
import { formatDate } from "common/Functions";
import useProfileView from "modules/candidate/Profile/components/DrawerFullView/useProfileView";
import Tooltip from "common/Tippy";

const AccordionHeader = ({ show, toggle, header, application_id }) => {
	const title = get(header, "title", "");
	const userName = get(header, "user_name", "");
	const visibleStatus = get(header, "visibleStatus", "");
	const dueDate = get(header, "due_date");

	const { setIsOpen, setApplicationId, setIsFromProcesses } = useProfileView();

	const openDrawer = () => {
		setIsOpen(true);
		setApplicationId(application_id);
		setIsFromProcesses(true);
	};

	return (
		<>
			<div className={styles.accordionHeader}>
				<div className={styles.header} onClick={toggle}>
					<div className={styles.title}>
						<span
							className={cx(styles.arrowDown, { [styles["show"]]: show })}
						/>
						<Tooltip content={title} theme="dark" overflow="hidden">
							<span className={styles.tooltip}>{title}</span>
						</Tooltip>
					</div>
					<div className={styles.description}>
						<p>By {userName}</p>
						<p>{visibleStatus}</p>
						<p>Due: {dueDate ? formatDate(dueDate) : "--/--/----"}</p>
					</div>
				</div>
				<div className={styles.action}>
					<button onClick={() => openDrawer()}>Full view</button>
				</div>
			</div>
		</>
	);
};
export default AccordionHeader;
