/* eslint-disable no-prototype-builtins */
import React, { useEffect, useRef, useState } from "react";
import styles from "./vacancy-view-propose-interview-candidate-field.module.scss";
import { ReactComponent as UserIcon } from "static/icons/user-03.svg";
import { Controller, useFormContext } from "react-hook-form";
import { OverlayProvider } from "react-aria";
import { DropDownWithSearch } from "common/DropDownWithSearch";
import Skeleton from "react-loading-skeleton";
import debounce from "lodash/debounce";
import useGetCandidates from "../api/useGetCandidates";
import Avatar from "common/Avatar";
import { FieldError } from "common/FieldError";
import useVacancyStore from "../../vacancyStore";
import { MAX_CANDIDATES } from "../../VacancyViewProposeOffer/utils/constant";
import cx from "classnames";

const VacancyViewProposeInterviewCandidateField = ({
	isProposeAnotherInterview
}) => {
	const ref = useRef();
	const {
		control,
		watch,
		setValue,
		formState: { errors }
	} = useFormContext();
	const { drawerState, setDrawerState } = useVacancyStore();
	const [displayPaper, setDisplayPaper] = useState(false);
	const [debouncedSearch, setDebouncedSearch] = useState("");
	const vacancy = watch("vacancy");
	const candidate = watch("candidate");
	const { data, isLoading } = useGetCandidates(
		{
			filter: "candidate_list",
			vacancy_id: vacancy?.value,
			search: debouncedSearch,
			limit: MAX_CANDIDATES
		},
		{ enabled: !!vacancy?.value && !isProposeAnotherInterview }
	);

	useEffect(() => {
		if (candidate) {
			setValue("to", [{ email: candidate.email, _id: candidate._id }]);
		}
	}, [candidate]);

	const debouncedOnChange = React.useCallback(
		debounce(value => {
			setDebouncedSearch(value);
		}, 500),
		[]
	);

	const onSearchChange = e => {
		let value = e.target.value;
		debouncedOnChange(value);
	};

	const onChangeCandidate = (item, onChange) => {
		onChange({ ...item, email: item.email, _id: item._id });
		setDisplayPaper(false);
		setDebouncedSearch("");
		setDrawerState({
			...drawerState,
			applicationId: item._id,
			companyType: item.account_type
		});
	};

	return (
		<div ref={ref}>
			<label className={styles.label}>
				<UserIcon className={styles.icon} width={20} height={21} />
				Candidate
			</label>
			<Controller
				name="candidate"
				control={control}
				render={({ field: { onChange, value } }) => (
					<OverlayProvider>
						<DropDownWithSearch
							popoverContainer={ref.current}
							variant="parent-width"
							paperClassName={styles.paperClassName}
							rootClassname={styles.rootDropDown}
							dropDownInputClassName={styles.dropDownInputClassName}
							displaySelectedValues
							onClose={() => setDisplayPaper(false)}
							displayPaper={displayPaper}
							displayFooter={false}
							onSearchChange={onSearchChange}
							onInputClick={() => {
								if (vacancy) setDisplayPaper(prev => !prev);
							}}
							value={
								value ? (
									<div
										className={cx(styles.candidateValue, {
											[styles.disabled]: isProposeAnotherInterview
										})}
									>
										<Avatar
											size={24}
											name={`${value.first_name} ${value.last_name}`}
											avatar={value.avatar}
											className={
												isProposeAnotherInterview ? styles.disabledAvatar : ""
											}
										/>
										{`${value.first_name} ${value.last_name}`}
									</div>
								) : (
									""
								)
							}
							hideResetButton
							isDisabled={isProposeAnotherInterview}
							content={
								isLoading ? (
									<LoadingSekeleton />
								) : (
									<div className={styles.dropdownContent}>
										{data?.map(item => (
											<div
												className={styles.entityContainer}
												key={item._id}
												onClick={() => {
													onChangeCandidate(item, onChange);
												}}
											>
												{item.hasOwnProperty("avatar") && (
													<Avatar
														size={32}
														name={`${item.first_name} ${item.last_name}`}
														avatar={item.avatar}
													/>
												)}
												{`${item.first_name} ${item.last_name}`}
											</div>
										))}
									</div>
								)
							}
							placement="bottom start"
						/>
					</OverlayProvider>
				)}
			/>
			<FieldError className={styles.error} error={errors.candidate} />
		</div>
	);
};

const LoadingSekeleton = () => {
	return Array(3)
		.fill(0)
		.map(item => (
			<div className={styles.entityContainer} key={item}>
				<Skeleton circle width={38} height={38} />
				<Skeleton width={113} height={11} />
			</div>
		));
};

export default VacancyViewProposeInterviewCandidateField;
