import { useEffect, useState } from "react";

const useArrowKeyNavigation = options => {
	const { list, handle, initialIndex = 0 } = options;
	const [selectedIndex, setSelectedIndex] = useState(initialIndex);

	const handleKeyDown = event => {
		const totalItems = list.length;
		switch (event.key) {
			case "ArrowUp":
				setSelectedIndex(prevIndex =>
					prevIndex > 0 ? prevIndex - 1 : totalItems - 1
				);
				break;
			case "ArrowDown":
				setSelectedIndex(prevIndex =>
					prevIndex < totalItems - 1 ? prevIndex + 1 : 0
				);
				break;
			case "Enter":
				handle(list[selectedIndex]);
				break;
			case "Escape":
				setSelectedIndex(0);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [selectedIndex]);

	useEffect(() => {
		if (open) {
			const index = list.indexOf(list[selectedIndex]);
			setSelectedIndex(index === -1 ? 0 : index);
		}
	}, [list[selectedIndex]]);

	return selectedIndex;
};

export default useArrowKeyNavigation;
