import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_ACTIVE_JOBS } from "config/api-endpoints";
const getRequests = body => {
	return client(GET_ACTIVE_JOBS, {
		body
	});
};
export const GET_ACTIVE_REQUESTS_QUERY_KEY = "GET_ACTIVE_REQUESTS_QUERY_KEY";
function useGetRequests(body) {
	return useQuery(
		[GET_ACTIVE_REQUESTS_QUERY_KEY, body],
		() => getRequests(body),
		{
			refetchOnWindowFocus: false
		}
	);
}
export default useGetRequests;
