import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export const cultureSchema = yup
	.object()
	.shape({
		employeeDevelopment: yup.array(),
		workLifeBalance: yup.array(),
		culturesAndValues: yup.array(),
		companyEnvironment: yup.array(),
		employeeWellbeing: yup.array()
	})
	.test(
		"at-least-one-selected",
		"Add at least one company culture & value to save. This will help your company stand out and appear higher in search results.",
		function(values) {
			const {
				employeeDevelopment,
				workLifeBalance,
				culturesAndValues,
				companyEnvironment,
				employeeWellbeing
			} = values;

			const isValid = [
				employeeDevelopment,
				workLifeBalance,
				culturesAndValues,
				companyEnvironment,
				employeeWellbeing
			].some(field => field?.length > 0);

			if (!isValid) return false;
			return true;
		}
	);

export default function useCompanyCultureForm() {
	return useForm({
		resolver: yupResolver(cultureSchema),
		shouldUnregister: true
	});
}
