import React from "react";
import styles from "./text-area.module.scss";
import cx from "classnames";

const TEXT_AREA_PLACEHOLDER = "Start typing...";
const TEXT_AREA_MAX_LENGTH = 2000;

const TextArea = ({
	value,
	onChange: onChange,
	placeholder = TEXT_AREA_PLACEHOLDER,
	maxLength = TEXT_AREA_MAX_LENGTH,
	wrapperClassName,
	textAreaClassName,
	lengthClassName,
	children,
	...props
}) => {
	return (
		<div className={cx(styles.textAreaWrapper, wrapperClassName)}>
			<textarea
				placeholder={placeholder}
				className={cx(styles.textarea, textAreaClassName)}
				value={value}
				onChange={onChange}
				maxLength={maxLength}
				{...props}
			/>
			{children || (
				<span className={cx(styles.length, lengthClassName)}>
					{value.length}/{maxLength}
				</span>
			)}
		</div>
	);
};

export default TextArea;
