import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { array, object, string } from "yup";
import {
	ADD_INTERVIEW_NOTE_MAX_LENGTH,
	LOCATION_TYPES,
	MAX_LENGTH_IN_BYTES
} from "../helper/constants";
import { getDefaultValues, isTimeValid } from "../helper/utils";
import { bytesToSize, convertDateAndTimeUsingTimezone } from "config/helpers";
import { useGetUser } from "hooks/useGetUser";
import { isSameDay, today } from "@internationalized/date";

const timeSlotSchema = object()
	.shape({
		startTime: object(),
		endTime: object()
	})
	.test("end-time-after-start", "End time must be after start time", function(
		value
	) {
		const valid = isTimeValid(value.startTime, value.endTime);
		if (valid) return true;
		return this.createError({
			path: `${this.path}.startTime`,
			message: "End time must be after start time"
		});
	})
	.test("unique-time", "Please choose unique times", function(value) {
		const timesList = [];
		const getPath = time => {
			const { hour, minute } = time.startTime;
			const { hour: endHour, minute: endMinute } = time.endTime;
			return `${hour}-${minute}-${endHour}-${endMinute}`;
		};
		for (const time of this.parent) {
			timesList.push(getPath(time));
		}
		return timesList.filter(p => getPath(value) === p).length > 1
			? this.createError({
					path: `${this.path}.startTime`,
					message: "Please choose unique times"
			  })
			: true;
	});

export const useProposePermanentInterviewForm = ({
	candidate,
	interview,
	applicationId,
	job
}) => {
	const connectedUser = useGetUser();
	const schema = object().shape({
		dates: array()
			.of(
				object({
					date: object().test(
						"Invalid",
						"Availability slots should be in the future",
						function(value) {
							const now = window.moment.tz(connectedUser.timezone).unix();

							if (isSameDay(today(connectedUser.timezone), value)) {
								const invalidTimeIndex = this.parent.times.findIndex(time => {
									const startDateTime = convertDateAndTimeUsingTimezone(
										{
											year: value.year,
											month: value.month,
											day: value.day,
											hour: time.startTime.hour,
											minute: time.startTime.minute
										},
										connectedUser.timezone
									).unix();
									return startDateTime < now;
								});

								if (invalidTimeIndex !== -1) {
									return this.createError({
										path: `dates[0].times[${invalidTimeIndex}].startTime`, // dates[0] because today will always be first element of dates array
										message: "Availability slots should be in the future"
									});
								}
							}
							return true;
						}
					),
					times: array().of(timeSlotSchema)
				})
			)
			.test("Required", "Please select a day", function(value) {
				return value.length > 0;
			}),
		attendees: array().test("Required", "Please select attendees", function(
			value
		) {
			return value?.[0]?.children.length > 0;
		}),
		locationValue: object()
			.nullable()
			.test("Missing fields", "Please enter a valid address", function(value) {
				if (this.parent.locationType === LOCATION_TYPES.online.value)
					return true;
				else if (
					this.parent.locationType === LOCATION_TYPES.companyAddress.value
				) {
					return !!value;
				}
				if (value) {
					const address = value;
					if (!address.street)
						return this.createError({ message: "Street is required" });
					if (!address.number)
						return this.createError({ message: "Number is required" });
					if (!address.city)
						return this.createError({ message: "City is required" });
					if (!address.zip)
						return this.createError({ message: "Zip is required" });
					if (!address.country)
						return this.createError({ message: "Country is required" });
				}
				return true;
			}),
		note: string().test(
			`The input is more than ${ADD_INTERVIEW_NOTE_MAX_LENGTH} characters long`,
			function(value) {
				if (!value) return true;
				else return value?.length <= ADD_INTERVIEW_NOTE_MAX_LENGTH;
			}
		),
		content: string().test(
			"content-size",
			({ value }) => {
				return `Content is too long (${bytesToSize(
					new TextEncoder().encode(value).length
				)}), max is ${bytesToSize(MAX_LENGTH_IN_BYTES)}`;
			},
			value => {
				return new TextEncoder().encode(value).length < MAX_LENGTH_IN_BYTES;
			}
		),
		content_placeholders: array().of(
			object({
				_id: string().required(),
				value: string().required(),
				key: string(),
				entity: string()
			})
		),
		...(!applicationId && {
			vacancy: object({
				label: string().required(),
				value: string()
					.nullable()
					.required()
			})
				.nullable()
				.test("Required", "Please select a vacancy", function(value) {
					return value?.value;
				}),
			candidate: object({
				_id: string()
					.nullable()
					.required()
			})
				.nullable()
				.test("Required", "Please select a candidate", function(value) {
					return value?._id;
				})
		})
	});

	const formContext = useForm({
		resolver: yupResolver(schema),
		mode: "onChange",
		defaultValues: getDefaultValues({
			interview,
			connectedUser,
			candidate,
			job
		}),
		shouldUnregister: false
	});

	return formContext;
};
