import React, { useMemo } from "react";
import styles from "./vacancy-view-propose-interview-first-step.module.scss";
import { ReactComponent as UsersIcon } from "static/icons/users.svg";
import { ReactComponent as CalendarPlusIcon } from "static/icons/calendar-plus-1.svg";
import { ReactComponent as MapPinIcon } from "static/icons/map-pin.svg";
import { ReactComponent as CommentIcon } from "static/icons/comment-1.svg";
import { ReactComponent as LinkExternalIcon } from "static/icons/link-external-02.svg";
import { ReactComponent as InfoIcon } from "static/icons/info-circle2.svg";
import Avatar from "common/Avatar/index";
import { VacancyViewAttendeesPicker } from "../../VacancyViewAttendeesPicker";
import { Controller, useFormContext } from "react-hook-form";
import { VacancyViewLocationPicker } from "../../VacancyViewLocationPicker";
import { FieldError } from "common/FieldError";
import VacancyViewPlannedInterviews from "../../VacancyViewPlannedInterviews/VacancyViewPlannedInterviews";
import useVacancyStore from "../../vacancyStore";
import VacancyViewProposeInterviewVacancyField from "../VacancyViewProposeInterviewVacancyField/VacancyViewProposeInterviewVacancyField";
import VacancyViewProposeInterviewCandidateField from "../VacancyViewProposeInterviewCandidateField/VacancyViewProposeInterviewCandidateField";
import { Link } from "react-router";
import { getAttendees, getCalendarEventAttendees } from "../../helper/utils";
import { useGetUser } from "hooks/useGetUser";
import Tooltip from "common/Tippy";
import { PROPOSE_NEW_INTERVIEW } from "../../helper/constants";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import TextArea from "common/text-area";
import VacancyViewProposeInterviewTimeSlots from "../VacancyViewProposeInterviewTimeSlots/VacancyViewProposeInterviewTimeSlots";

export default function VacancyViewProposeInterviewFirstStep({
	isVisible,
	job = {},
	useVacancyAndCandidateFields,
	timezone,
	action,
	isDeclineInterview,
	isPokeDataLoading
}) {
	const connectedUser = useGetUser();
	const { workflowId } = useOnboardingStore();
	const {
		control,
		watch,
		formState: { errors }
	} = useFormContext();
	const candidate = watch("candidate");
	const vacancy = watch("vacancy");

	const { drawerState } = useVacancyStore();

	const memoizedQueryFn = useMemo(
		() =>
			useVacancyAndCandidateFields
				? getCalendarEventAttendees(connectedUser, vacancy?.value, workflowId)
				: getAttendees(connectedUser, job._id),
		[vacancy?.value, workflowId, job._id]
	);

	return (
		<>
			<div
				className={styles.form}
				style={{ display: isVisible ? "block" : "none" }}
			>
				{action && action !== PROPOSE_NEW_INTERVIEW && isDeclineInterview && (
					<div className={styles.banner}>
						<span>ℹ️</span>
						This candidate already has an interview proposal or a planned
						interview in this vacancy. Please review before proceeding.
					</div>
				)}
				{!useVacancyAndCandidateFields ? (
					<>
						<div className={styles.formTitle}>
							Plan and Notify {drawerState.candidateName} with the interview
							details
						</div>
						<div className={styles.candidateInfos}>
							<Avatar
								className={styles.avatar}
								size={42}
								src={drawerState.avatar}
								name={drawerState.candidateName}
							/>
							<div>
								<div className={styles.candidateName}>
									{drawerState.candidateName}
								</div>
								<div className={styles.candidatePosition}>
									{drawerState.mainFunction}
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div className={styles.row}>
							<div className={styles.field}>
								<VacancyViewProposeInterviewVacancyField
									isProposeAnotherInterview={action !== PROPOSE_NEW_INTERVIEW}
								/>
							</div>
							<div className={styles.field}>
								<VacancyViewProposeInterviewCandidateField
									isProposeAnotherInterview={
										!vacancy || action !== PROPOSE_NEW_INTERVIEW
									}
								/>
							</div>
						</div>
						{candidate && (
							<div className={styles.candidateInfos}>
								<Avatar
									className={styles.avatar}
									size={42}
									src={candidate.avatar}
									name={`${candidate.first_name} ${candidate.last_name}`}
								/>
								<div>
									<div className={styles.candidateName}>
										{`${candidate.first_name} ${candidate.last_name}`}
									</div>
									<div className={styles.candidatePosition}>
										{candidate.user_function}
									</div>
								</div>
								<Link
									to={`/vacancy/${drawerState.job?._id}?application_id=${drawerState.applicationId}`}
									target="_blank"
									className={styles.viewApplication}
								>
									View Application
									<LinkExternalIcon />
								</Link>
							</div>
						)}
					</>
				)}
				<div className={styles.fields}>
					<div>
						<div className={styles.label}>
							<UsersIcon />
							<span>Invite attendees</span>
						</div>
						<VacancyViewAttendeesPicker
							selectedAttendees={watch("attendees")}
							queryFn={() => memoizedQueryFn}
							queryId={vacancy?.value}
						/>
						<FieldError className={styles.error} error={errors.attendees} />
					</div>
					<div>
						<div className={styles.label}>
							<CalendarPlusIcon />
							<span>Date & Time</span>
						</div>
						<VacancyViewProposeInterviewTimeSlots findBestTimeSlots />
						{errors.dates?.message ? (
							<FieldError className={styles.error} error={errors.dates} />
						) : null}
					</div>
					<div>
						<div className={styles.label}>
							<MapPinIcon />
							<span>Location</span>
						</div>
						<div className={styles.locationPicker}>
							<VacancyViewLocationPicker />
							<FieldError
								className={styles.error}
								error={errors.locationValue}
							/>
						</div>
					</div>
					<div className={styles.noteContainer}>
						<div className={styles.label}>
							<CommentIcon />
							<span>Description</span>
							<Tooltip
								theme="dark"
								content="The interview’s description is visible to all participants, including the candidate."
							>
								<InfoIcon width={18} height={18} />
							</Tooltip>
						</div>

						<Controller
							name="note"
							control={control}
							render={({ field: { value, onChange } }) => (
								<TextArea value={value} onChange={onChange} />
							)}
						/>
						<FieldError className={styles.error} error={errors.note} />
					</div>
				</div>
			</div>
			<div
				className={styles.sidebar}
				style={{ display: isVisible ? "flex" : "none" }}
			>
				<VacancyViewPlannedInterviews
					job={job}
					timezone={timezone}
					isPokeDataLoading={isPokeDataLoading}
				/>
			</div>
		</>
	);
}
