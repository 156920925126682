import React, { useState } from "react";
import useVacancyStore from "../../vacancyStore";
import Drawer from "rc-drawer";
import styles from "./vacancy-view-phone-screen-drawer.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/close-icon.svg";
import { ReactComponent as ArrowRightIcon } from "static/icons/arrow-right-1.svg";
import { ReactComponent as BackIcon } from "static/icons/IconBack.svg";
import { Button } from "common/Button";
import { FormProvider } from "react-hook-form";
import cx from "classnames";
import useProfileView from "modules/candidate/Profile/components/DrawerFullView/useProfileView";
import LeaveHook from "common/LeaveHook";
import VacancyViewInterviewNewMail from "../VacancyViewPhoneScreenNewMail/VacancyViewPhoneScreenNewMail";
import { preparePhoneScreenPayload } from "../../helper/utils";
import { useGetUser } from "hooks/useGetUser";
import { useInviteUserToInterview } from "../../hooks/useInviteUserToInterview";
import toaster from "common/Toaster";
import { queryCache } from "react-query";
import { useDispatch } from "react-redux";
import { fetchPoke } from "modules/vacancy/actions";
import VacancyViewAddMailModal from "../../VacancyViewAddMailModal/VacancyViewAddMailModal";
import VacancyViewMissingPlaceholderWarning from "../../VacancyViewMissingPlaceholderWarning/VacancyViewMissingPlaceholderWarning";
import { CANCELLED, EXPIRED, DONE } from "config";
import VacancyViewPhoneScreenFirstStep from "../VacancyViewPhoneScreenFirstStep/VacancyViewPhoneScreenFirstStep";
import { usePhoneScreenForm } from "../../hooks/usePhoneScreenForm";
import { renderError } from "config/helpers";
import usePokeData from "modules/candidate/Profile/components/DrawerFullView/api/usePokeData";
import get from "lodash/get";

const STEP_ONE = "STEP_ONE";
const STEP_TWO = "STEP_TWO";

export default function VacancyViewPhoneScreenDrawer({
	job,
	onClose,
	isFromVacanciesTable,
	poke
}) {
	const { drawerState, setDrawerState } = useVacancyStore();
	const { data: pokeData } = usePokeData({
		id: job ? null : drawerState.applicationId
	});
	const isDeclineInterview =
		drawerState.isApplicantHasAnOffer &&
		drawerState.interviewStatus !== CANCELLED &&
		drawerState.interviewStatus !== DONE &&
		drawerState.interviewStatus !== EXPIRED;
	const [showDiscardWarning, setShowDiscardWarning] = useState(false);
	const [showAddMailModal, setShowAddMailModal] = useState(false);
	const { setIsOpen, setApplicationId, isFromCardActions } = useProfileView();
	const [currentStep, setCurrentStep] = useState(STEP_ONE);
	const [
		showMissingPlaceholderWarning,
		setShowMissingPlaceholderWarning
	] = useState(false);
	const authenticatedUser = useGetUser();
	const formContext = usePhoneScreenForm();
	const {
		formState: { dirtyFields },
		getValues,
		setValue
	} = formContext;
	const isDirty = Object.keys(dirtyFields).length > 0;
	const [
		mutate,
		{ isLoading: isProposePhoneScreenLoading }
	] = useInviteUserToInterview();
	const dispatch = useDispatch();

	const closeDrawer = () => {
		if (isFromCardActions) {
			setDrawerState({
				open: false,
				component: ""
			});
		} else {
			setDrawerState({
				open: false,
				component: ""
			});
			if (!isFromVacanciesTable) {
				setIsOpen(true);
			}
			setApplicationId(drawerState.applicationId);
		}
	};

	const onClickSubmit = () => {
		if (currentStep === STEP_ONE) {
			formContext
				.trigger(["dates", "attendees", "phone", "note"], {
					shouldFocus: true
				})
				.then(isValid => {
					if (isValid) {
						if (!getValues("to")[0]?.email) {
							setShowAddMailModal(true);
						} else {
							setCurrentStep(STEP_TWO);
						}
					}
				});
		} else {
			formContext.handleSubmit(onSubmit)();
		}
	};

	const onSubmit = (data, args = {}) => {
		const { logEvent = false } = args;
		const payload = preparePhoneScreenPayload({
			formData: data,
			logEvent: logEvent,
			companyType: drawerState.companyType,
			applicationId: drawerState.applicationId,
			profileId: drawerState.profileId,
			interviewId: drawerState.interviewId,
			isDeclineInterview,
			timezone: authenticatedUser.timezone
		});
		mutate(
			{
				...payload,
				isDeclineInterview
			},
			{
				onSuccess: () => {
					setDrawerState({ open: false });
					if (!isFromVacanciesTable) {
						setIsOpen(true);
					}
					toaster.success(
						"The new interview proposition has successfully been sent!"
					);
					setApplicationId(drawerState.applicationId);
					queryCache.invalidateQueries("getDataPipeline");
					if (poke?._id) return dispatch(fetchPoke({ id: poke._id }));
				},
				onError: renderError
			}
		);
	};

	const onSaveAsLogged = () => {
		const dates = getValues("dates");
		if (dates.length > 1 || get(dates, "[0].times", []).length > 1) {
			toaster.danger(
				"You can only choose one date and time for logged interviews."
			);
		} else {
			formContext.handleSubmit(onSubmit)({ logEvent: true });
		}
	};

	const onClickCancel = () => {
		if (isDirty) {
			setShowDiscardWarning(true);
		} else {
			onClose();
		}
	};

	const onClickBack = () => {
		setCurrentStep(STEP_ONE);
	};

	const isLoading = formContext.isValidating || isProposePhoneScreenLoading;
	return (
		<Drawer
			open={drawerState.open}
			width="1040px"
			height="100%"
			placement={"right"}
			style={{ zIndex: 1000, backgroundColor: "transparent" }}
			level={"root"}
			onClose={onClickCancel}
		>
			<FormProvider {...formContext}>
				<div className={styles.container}>
					<div className={styles.header}>
						<div className={styles.title}>Phone screening</div>
						<button
							className={styles.closeButton}
							onClick={onClickCancel}
							disabled={isLoading}
						>
							<CloseIcon />
						</button>
					</div>
					<div className={styles.body}>
						<VacancyViewPhoneScreenFirstStep
							isVisible={currentStep === STEP_ONE}
							job={job ?? pokeData?.job}
						/>
						<VacancyViewInterviewNewMail
							isVisible={currentStep === STEP_TWO}
							isDeclineInterview={isDeclineInterview}
							jobData={job ?? pokeData?.job}
						/>
					</div>
					<div className={cx(styles.footer, isLoading && styles.disabled)}>
						<div className={styles.group}>
							<Button
								text="Cancel"
								onClick={onClickCancel}
								className={styles.cancelBtn}
							/>
						</div>
						<div className={styles.group}>
							{currentStep === STEP_TWO ? (
								<Button
									text="Back"
									onClick={onClickBack}
									className={styles.backBtn}
									icon={<BackIcon />}
								/>
							) : (
								<Button
									onClick={onSaveAsLogged}
									text="Save as Logged"
									className={styles.saveAsLoggedBtn}
								/>
							)}
							<Button
								onClick={onClickSubmit}
								text="Propose interview"
								rightIcon={currentStep === STEP_ONE ? <ArrowRightIcon /> : null}
								isLoading={isLoading}
							/>
						</div>
					</div>
				</div>
				<LeaveHook
					dirty={isDirty}
					enforceWarning={showDiscardWarning}
					confirmationModal={{
						onDiscard: onClose,
						onClose: () => setShowDiscardWarning(false),
						isLoading: isLoading,
						disabled: isLoading
					}}
				/>
				{showAddMailModal && (
					<VacancyViewAddMailModal
						logInterviewHandler={onSaveAsLogged}
						closeDrawer={closeDrawer}
						profileId={drawerState.profileId}
						vacancyId={job._id}
						activeCompany={authenticatedUser?.active_company}
						setShowAddMailModal={setShowAddMailModal}
						onEmailAdded={email => {
							setValue("to", [{ email, _id: drawerState.profileId }]);
							onClickSubmit();
						}}
					/>
				)}
				{showMissingPlaceholderWarning && (
					<VacancyViewMissingPlaceholderWarning
						onClose={() => setShowMissingPlaceholderWarning(false)}
						placeholderLabel="interview.link"
					/>
				)}
			</FormProvider>
		</Drawer>
	);
}
