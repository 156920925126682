import React from "react";
import styles from "./vacancy-view-phone-screen-first-step.module.scss";
import { ReactComponent as CalendarPlusIcon } from "static/icons/calendar-plus-1.svg";
import { ReactComponent as PhoneIcon } from "static/icons/phone-grey.svg";
import { ReactComponent as CommentIcon } from "static/icons/comment-1.svg";
import Avatar from "common/Avatar/index";
import { Controller, useFormContext } from "react-hook-form";
import { FieldError } from "common/FieldError";
import VacancyViewPlannedInterviews from "../../VacancyViewPlannedInterviews/VacancyViewPlannedInterviews";
import useVacancyStore from "../../vacancyStore";
import PhoneInput from "common/PhoneInput/new-phone-input";
import VacancyViewProposeInterviewTimeSlots from "../../VacancyViewProposeInterview/VacancyViewProposeInterviewTimeSlots/VacancyViewProposeInterviewTimeSlots";
import { useGetUser } from "hooks/useGetUser";

export default function VacancyViewPhoneScreenFirstStep({ isVisible, job }) {
	const user = useGetUser();
	const {
		control,
		setValue,
		formState: { errors }
	} = useFormContext();
	const { drawerState } = useVacancyStore();

	return (
		<>
			<div
				className={styles.form}
				style={{ display: isVisible ? "block" : "none" }}
			>
				<div className={styles.formTitle}>
					Plan and Notify {drawerState.candidateName} with the phone screen
					details
				</div>
				<div className={styles.candidateInfos}>
					<Avatar
						className={styles.avatar}
						size={42}
						src={drawerState.avatar}
						name={drawerState.candidateName}
					/>
					<div>
						<div className={styles.candidateName}>
							{drawerState.candidateName}
						</div>
						<div className={styles.candidatePosition}>
							{drawerState.mainFunction}
						</div>
					</div>
				</div>
				<div className={styles.fields}>
					<div>
						<div className={styles.label}>
							<CalendarPlusIcon />
							<span>Date & Time</span>
						</div>
						<VacancyViewProposeInterviewTimeSlots />
						{errors.dates?.message ? (
							<FieldError className={styles.error} error={errors.dates} />
						) : null}
					</div>
					<div>
						<div className={styles.label}>
							<PhoneIcon className={styles.phoneIcon} />
							<span>Phone number</span>
						</div>
						<Controller
							name="phone"
							control={control}
							render={({ field: { onChange, value } }) => (
								<PhoneInput
									value={value}
									phoneContainerClassName={styles.phoneContainer}
									onChange={(value, countryCode, countryCodeOnly = false) => {
										if (countryCodeOnly) {
											setValue("phone", {
												value,
												countryCode: countryCode
											});
										} else {
											onChange({
												value,
												countryCode: countryCode
											});
										}
									}}
								/>
							)}
						/>
						<FieldError className={styles.error} error={errors.phone} />
					</div>

					<div className={styles.noteContainer}>
						<div className={styles.label}>
							<CommentIcon />
							<span>Description</span>
						</div>

						<Controller
							name="note"
							control={control}
							render={({ field: { value, onChange } }) => (
								<textarea
									placeholder="Start typing..."
									className={styles.textarea}
									value={value}
									onChange={e => onChange(e.target.value)}
								/>
							)}
						/>
						<FieldError className={styles.error} error={errors.note} />
					</div>
				</div>
			</div>
			<div
				className={styles.sidebar}
				style={{ display: isVisible ? "block" : "none" }}
			>
				<VacancyViewPlannedInterviews job={job} timezone={user.timezone} />
			</div>
		</>
	);
}
