import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, array } from "yup";

export const useProfileForm = hasMultiSites => {
	let schemaTemplate = {
		department: object().test(
			"Required",
			"Please select a department",
			function(value) {
				return !!value?.value;
			}
		),
		category: object().test("Required", "Please select a category", function(
			value
		) {
			return !!value?.value;
		}),
		subCategory: object().test(
			"Required",
			"Please select a subCategory",
			function(value) {
				return !!value?.value;
			}
		),
		_function: object().test("Required", "Please select a function", function(
			value
		) {
			return !!value?.value;
		}),
		seniority: object().test("Required", "Please select a seniority", function(
			value
		) {
			return !!value?.value;
		}),
		skills: array()
			.min(1, "Value is required and can't be empty.")
			.required("Value is required and can't be empty"),
		languages: array().required("Please select at least one language.")
	};

	if (hasMultiSites) {
		schemaTemplate.site = object().test(
			"Required",
			"Please select a site",
			function(value) {
				return !!value?.value;
			}
		);
	}

	const schema = object().shape(schemaTemplate);

	const res = useForm({
		resolver: yupResolver(schema),
		shouldUnregister: false
	});

	return res;
};
