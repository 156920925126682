import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import style from "../../my-company.module.scss";

const CompanyCultureLoadingState = () => {
	const fields = [
		"Employee development",
		"Work-Life balance",
		"Cultures & values",
		"Company environment",
		"Employee wellbeing"
	];
	return (
		<>
			<div className={style.headerBlock}>
				<h3>Company culture & values</h3>
			</div>
			<SkeletonTheme style={{ borderRadius: "3px" }} color="#E5E5E5">
				<div className={style.card}>
					{fields.map((label, i) => (
						<div key={i} className={style.field}>
							<div className={style.label}>{label}</div>
							<div className={style.infoLabel}>
								<div className={style.chipsContainer}>
									<span className={style.industriesChip}>
										<Skeleton width={137} height={16} />
									</span>
									<span className={style.industriesChip}>
										<Skeleton width={54} height={16} />
									</span>
								</div>
							</div>
						</div>
					))}
				</div>
			</SkeletonTheme>
		</>
	);
};

export default CompanyCultureLoadingState;
