import React from "react";

import { ReactComponent as CalendarOneIcon } from "static/icons/calendar-one.svg";
import { ReactComponent as ClockIcon } from "static/icons/clock-1.svg";
import styles from "./planned-interviews-history.module.scss";
import useVacancyStore from "../../vacancyStore";
import { useGetLatestInterviews } from "../../hooks/useGetLatestInterviews";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { formatDateUsingTimezone } from "common/Functions";

export default function PlannedInterviewsHistory({
	job = {},
	timezone,
	isPokeDataLoading
}) {
	const { drawerState } = useVacancyStore();

	const { data, isLoading } = useGetLatestInterviews({
		vacancyId: job._id,
		profileId: drawerState.profileId
	});

	return (
		<div>
			<div className={styles.historyHeader}>
				<ClockIcon />
				<div>
					<div className={styles.historyTitle}>History of Interviews</div>
					{drawerState.candidateName && (
						<div className={styles.historyInterviewer}>
							<span>with </span>
							<span className={styles.interviewerName}>
								{drawerState.candidateName}
							</span>
						</div>
					)}
				</div>
			</div>
			<div className={styles.historyList}>
				{isLoading || isPokeDataLoading ? (
					<SkeletonTheme color="#E2E8F0">
						{Array.from({ length: 8 }).map((_, index) => (
							<Skeleton
								key={index}
								width={"100%"}
								height={10}
								style={{ borderRadius: 99, marginBottom: "16px" }}
							/>
						))}
					</SkeletonTheme>
				) : !data?.history?.length ? (
					<div>
						There are no past interview. Once interviews are conducted, they
						will appear here.
					</div>
				) : (
					data?.history?.map(item => (
						<div key={item._id} className={styles.historyItem}>
							<div className={styles.historyDate}>
								<CalendarOneIcon />
								<span>
									{formatDateUsingTimezone(
										item.new_interview_start_date,
										true,
										"DD/MM/YYYY",
										false,
										timezone
									)}
								</span>
							</div>
							<div className={styles.historyTime}>
								{`${formatDateUsingTimezone(
									item.new_interview_start_date,
									true,
									"HH:mm",
									false,
									timezone
								)} - ${formatDateUsingTimezone(
									item.new_interview_end_date,
									true,
									"HH:mm",
									false,
									timezone
								)}`}
							</div>
						</div>
					))
				)}
			</div>
		</div>
	);
}
