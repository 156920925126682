import { useForm } from "react-hook-form";
import { REMINDER_INTERVALS } from "../utils/constant";
import { TIMEZONE } from "config";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { MINUTES_IN_HOUR } from "config";
import { HOURS_IN_DAY } from "config";

const timeSchema = Yup.object({
	hour: Yup.number()
		.min(0)
		.max(HOURS_IN_DAY - 1)
		.required(),
	minute: Yup.number()
		.min(0)
		.max(MINUTES_IN_HOUR - 1)
		.required()
});

const timeSlotSchema = Yup.object({
	start_time: timeSchema.test(
		"is-valid-start-time",
		"Time is invalid",
		function(start_time) {
			const { end_time } = this.parent;
			const startInMinutes =
				start_time.hour * MINUTES_IN_HOUR + start_time.minute;
			const endInMinutes = end_time.hour * MINUTES_IN_HOUR + end_time.minute;

			return startInMinutes < endInMinutes;
		}
	),
	end_time: timeSchema
});

const daySchema = Yup.object({
	time_slots: Yup.array().of(
		timeSlotSchema.test("is-sequential", "Time is invalid", function(
			_,
			context
		) {
			const { path, parent, options } = context;
			const index = path.match(/\d+/g)?.[1]; // Extract the index of the current time slot
			if (index > 0) {
				const previousSlot = parent[index - 1];
				const currentSlot = parent[index];
				const prevEnd =
					previousSlot.end_time.hour * MINUTES_IN_HOUR +
					previousSlot.end_time.minute;
				const currStart =
					currentSlot.start_time.hour * MINUTES_IN_HOUR +
					currentSlot.start_time.minute;
				if (currStart < prevEnd) {
					options.abortEarly = false;
					return context.createError({
						path: `${path}.start_time`,
						message: "Time is invalid"
					});
				}
			}
			return true;
		})
	)
});

const workDaysSchema = Yup.array().of(daySchema);

export const useCalendarSettingsForm = () => {
	return useForm({
		shouldUnregister: false,
		defaultValues: {
			timezone: TIMEZONE,
			reminder: REMINDER_INTERVALS[15],
			emailNotification: false,
			pushNotification: false,
			isWorkingHours: false
		},
		resolver: yupResolver(
			Yup.object().shape({
				work_days: workDaysSchema,
				timezone: Yup.object().test("is-invalid", "Timezone invalid", function(
					timezone
				) {
					return !!timezone?.value;
				})
			})
		)
	});
};
