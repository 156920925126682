import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { array, object, string } from "yup";
import {
	ADD_INTERVIEW_NOTE_MAX_LENGTH,
	MAX_LENGTH_IN_BYTES
} from "../helper/constants";
import { useGetUser } from "hooks/useGetUser";
import { isTimeValid } from "../helper/utils";
import { EMPTY_EMAIL } from "common/MessagingToolNewEmail/utils/constant";
import { bytesToSize, convertDateAndTimeUsingTimezone } from "config/helpers";
import { v4 as uuid } from "uuid";
import { parsePhoneNumber } from "libphonenumber-js";
import { PHONE_SCREEN } from "config";
import useVacancyStore from "../vacancyStore";
import { isSameDay, today } from "@internationalized/date";

const timeSlotSchema = object()
	.shape({
		startTime: object(),
		endTime: object()
	})
	.test("end-time-after-start", "End time must be after start time", function(
		value
	) {
		const valid = isTimeValid(value.startTime, value.endTime);
		if (valid) return true;
		return this.createError({
			path: `${this.path}.startTime`,
			message: "End time must be after start time"
		});
	})
	.test("unique-time", "Please choose unique times", function(value) {
		const timesList = [];
		const getPath = time => {
			const { hour, minute } = time.startTime;
			const { hour: endHour, minute: endMinute } = time.endTime;
			return `${hour}-${minute}-${endHour}-${endMinute}`;
		};
		for (const time of this.parent) {
			timesList.push(getPath(time));
		}
		return timesList.filter(p => getPath(value) === p).length > 1
			? this.createError({
					path: `${this.path}.startTime`,
					message: "Please choose unique times"
			  })
			: true;
	});

export const usePhoneScreenForm = () => {
	const { drawerState } = useVacancyStore();

	const connectedUser = useGetUser();
	const schema = object().shape({
		dates: array()
			.of(
				object({
					date: object().test(
						"Invalid",
						"Availability slots should be in the future",
						function(value) {
							const now = window.moment.tz(connectedUser.timezone).unix();

							if (isSameDay(today(connectedUser.timezone), value)) {
								const invalidTimeIndex = this.parent.times.findIndex(time => {
									const startDateTime = convertDateAndTimeUsingTimezone(
										{
											year: value.year,
											month: value.month,
											day: value.day,
											hour: time.startTime.hour,
											minute: time.startTime.minute
										},
										connectedUser.timezone
									).unix();
									return startDateTime < now;
								});

								if (invalidTimeIndex !== -1) {
									return this.createError({
										path: `dates[0].times[${invalidTimeIndex}].startTime`, // dates[0] because today will always be first element of dates array
										message: "Availability slots should be in the future"
									});
								}
							}
							return true;
						}
					),
					times: array().of(timeSlotSchema)
				})
			)
			.test("Required", "Please select a day", function(value) {
				return value.length > 0;
			}),
		phone: object().test("phone", "Invalid phone number", value => {
			if (value?.countryCode && value?.value) {
				try {
					const res = parsePhoneNumber(value.value, {
						extract: false
					});
					return res.isValid();
				} catch (error) {
					return false;
				}
			}
			return false;
		}),
		note: string().test(
			`The input is more than ${ADD_INTERVIEW_NOTE_MAX_LENGTH} characters long`,
			function(value) {
				if (!value) return true;
				else return value?.length <= ADD_INTERVIEW_NOTE_MAX_LENGTH;
			}
		),
		content: string().test(
			"content-size",
			({ value }) => {
				return `Content is too long (${bytesToSize(
					new TextEncoder().encode(value).length
				)}), max is ${bytesToSize(MAX_LENGTH_IN_BYTES)}`;
			},
			value => {
				return new TextEncoder().encode(value).length < MAX_LENGTH_IN_BYTES;
			}
		)
	});

	const formContext = useForm({
		resolver: yupResolver(schema),
		mode: "onChange",
		defaultValues: {
			id: uuid(),
			locationType: PHONE_SCREEN,
			phone: drawerState.phone
				? {
						countryCode: drawerState.phone.split(" ")[0],
						value: drawerState.phone.split(" ")[1]
				  }
				: null,
			dates: [],
			attendees: [
				{
					id: 1,
					label: "Collaborators",
					children: [
						{
							id: connectedUser.id,
							label: `${connectedUser.first_name} ${connectedUser.last_name}`
						}
					]
				}
			],
			...EMPTY_EMAIL,
			to: drawerState.candidateEmail
				? [{ email: drawerState.candidateEmail, _id: drawerState.profileId }]
				: []
		},
		shouldUnregister: false
	});

	return formContext;
};
