import React, { useEffect, useState, useRef, memo } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import useGeoLocation from "./useGeoLocation";
import toaster from "common/Toaster";
import { isEmptyAddress } from "config/helpers";
import { GOOGLE_API_KEY } from "config";

const mapStyles = {
	width: "100%",
	height: "100%"
};

const initialPos = {
	lat: 50.8503,
	lng: 4.3517
};

export function MapContainer({ google, address = {}, onAddressChange }) {
	const geoState = useGeoLocation();
	const mapRef = useRef();
	const [markerPosition, setMarkerPosition] = useState({
		lat: address.latitude || geoState.latitude || initialPos.lat,
		lng: address.longitude || geoState.longitude || initialPos.lng
	});

	const positionAndRecenter = latLng => {
		recenterMap(latLng);
		geoCode(latLng);
	};

	const recenterMap = ({ lat, lng } = {}) => {
		if (!mapRef.current) return;
		const maps = google.maps;
		const newCenter = new maps.LatLng(lat, lng);
		mapRef.current.map.panTo(newCenter);
		mapRef.current.map.setZoom(16);
	};

	useEffect(() => {
		if (!mapRef.current) return;
		let latLng;
		// don't update if geolocation changes if already geolocated
		if (isEmptyAddress(address) && !geoState.loading) {
			latLng = {
				lat: geoState.latitude,
				lng: geoState.longitude
			};
			setMarkerPosition(latLng);
			positionAndRecenter(latLng);
		} else if (
			!isEmptyAddress(address) &&
			markerPosition.lat !== address.latitude &&
			markerPosition.lng !== address.longitude
		) {
			latLng = {
				lat: address.latitude ?? markerPosition.lat,
				lng: address.longitude ?? markerPosition.lng
			};
			setMarkerPosition(latLng);
			recenterMap(latLng);
		}
	}, [
		geoState.latitude,
		geoState.longitude,
		address.latitude,
		address.longitude
	]);

	useEffect(() => {
		google.maps.event.addListenerOnce(mapRef.current.map, "idle", () => {
			recenterMap(markerPosition);
		});
	}, []);

	const markerDragEnd = (_, __, { latLng }) => {
		const latLngObj = {
			lat: latLng.lat(),
			lng: latLng.lng()
		};
		setMarkerPosition(latLngObj);
		positionAndRecenter(latLngObj);
	};

	return (
		<>
			<Map
				google={google}
				zoom={16}
				style={mapStyles}
				initialCenter={{
					lat: geoState.latitude,
					lng: geoState.longitude
				}}
				ref={mapRef}
				disableDefaultUI={true}
			>
				<Marker
					draggable={true}
					onDragend={markerDragEnd}
					position={{
						lat: markerPosition.lat || address.latitude,
						lng: markerPosition.lng || address.longitude
					}}
				/>
			</Map>
		</>
	);

	function geoCode(latLngObj) {
		const geocoder = new google.maps.Geocoder();
		geocoder.geocode({ location: latLngObj }, (result, status) => {
			if (status === google.maps.GeocoderStatus.OK) {
				if (result[0]) {
					getPlaceComponents(result[0], latLngObj);
				}
			} else {
				toaster.danger(
					"Address not found, make sure you've selected the right spot."
				);
			}
		});
	}

	function getPlaceComponents({ address_components, geometry }, latLng) {
		const fields = {
			route: "street",
			postal_code: "zip",
			street_number: "number",
			locality: "city",
			country: "country"
		};
		const address = address_components.reduce((acc, val) => {
			Object.keys(fields).forEach(key => {
				if (val.types.indexOf(key) !== -1) {
					if (key === "country") {
						acc[fields[key]] = val.long_name;
						acc["iso_country"] = val.short_name;

						return;
					}
					acc[fields[key]] = val.long_name;
				}
			});
			return acc;
		}, {});
		onAddressChange({
			...address,
			geometry,
			latitude: latLng.lat,
			longitude: latLng.lng
		});
	}
}

export default GoogleApiWrapper({
	apiKey: GOOGLE_API_KEY
})(memo(MapContainer));
