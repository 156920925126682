import React from "react";
import styles from "./collaborator-picker.module.scss";
import { ReactComponent as UserAddIcon } from "static/icons/users-plus-icon.svg";

import {
	SEARCH_SETTINGS_CALENDAR_COLLABORATORS,
	searchCalendarCollaborators
} from "modules/calendar-settings/hooks/api/useSearchCollaborators";
import { Picker } from "common/Picker";

const CollaboratorPicker = ({ fields, onAdd }) => {
	const handleChange = items => {
		const list = items?.[0]?.children;
		if (list?.length > 0) {
			onAdd(list);
		}
	};

	const filterData = data => {
		const fieldsIds = fields.map(element => element.id);
		return [
			{
				id: 1,
				label: "Collaborators",
				children:
					data?.[0]?.children.filter(el => !fieldsIds.includes(el.id)) || []
			}
		];
	};

	return (
		<>
			<Picker
				PrimaryHeaderContent={() => "Collaborators remaining"}
				SecondaryHeaderContent={() => "Collaborators selected"}
				displayRating={false}
				displaySelectedValues={false}
				addButtonComponent={props => (
					<div className={styles.addBtn} type="button" {...props}>
						<UserAddIcon />
						Add members
					</div>
				)}
				placement="bottom right"
				isMultipleSelect
				onConfirm={handleChange}
				queryId={SEARCH_SETTINGS_CALENDAR_COLLABORATORS}
				queryFn={(_, { search }) =>
					searchCalendarCollaborators({
						search
					})
				}
				value={[]}
				key="collaborators"
				useServerFilter={true}
				inlineChips
				filterData={filterData}
				shouldClearState
				emptyDataMessage="No collaborators has been found!"
			/>
		</>
	);
};

export default CollaboratorPicker;
