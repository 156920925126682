import React, { useRef, useState } from "react";
import styles from "./page-size-select.module.scss";
import ArrowDown from "common/AgTable/icons/ArrowDown";
import useOnClickOutside from "hooks/useOnClickOutside";
import useArrowKeyNavigation from "hooks/useArrowKeyNavigation";
import useUpdateTableLimit from "api/useUpdateTableLimit";

const PageSizeSelect = ({ limit, setLimit, rowsPages, tableTag }) => {
	const refUl = useRef();
	const [open, setOpen] = useState(false);

	const [updateTableLimit] = useUpdateTableLimit();

	const handleSelectNumberPage = value => {
		if (tableTag) {
			updateTableLimit({
				type: tableTag,
				size: value
			});
		}
		setLimit(value);
		setOpen(false);
	};

	useOnClickOutside(refUl, () => {
		setOpen(false);
	});

	const selectedIndex = useArrowKeyNavigation({
		list: rowsPages,
		initialIndex: rowsPages.indexOf(limit),
		handle: value => {
			if (open) {
				handleSelectNumberPage(value);
				setOpen(false);
			}
		}
	});

	return (
		<div className={styles.dropDownRow}>
			<span>Rows per page </span>
			<div className={styles.selectedNumber}>
				<button
					onClick={() => {
						setOpen(prevOpen => !prevOpen);
					}}
					type="button"
				>
					<span>{limit}</span>
					<ArrowDown width={"1.125rem"} height={"1.125rem"} stroke={1.5} />
				</button>
				{open && (
					<ul className={styles.dropDownUl} ref={refUl}>
						{rowsPages.map((row, index) => (
							<li
								key={row}
								className={[
									styles.dropDownLi,
									index === selectedIndex && styles.selected
								].join(" ")}
								onClick={() => handleSelectNumberPage(row)}
							>
								{row}
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	);
};

export default PageSizeSelect;
