import React from "react";
import styles from "./vacancy-view-times-list.module.scss";
import { ReactComponent as PlusIcon } from "static/icons/plus.svg";
import { ReactComponent as CloseIcon } from "static/icons/close-icon-grey.svg";
import NewTimePicker from "common/NewTimePicker";
import { useDateFormatter } from "react-aria";
import { getLocalTimeZone, Time } from "@internationalized/date";
import cx from "classnames";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { FieldError } from "common/FieldError";
import { useGetUser } from "hooks/useGetUser";
import { get } from "lodash";
import { TIMEZONE } from "config";
import { getTimeSlot } from "../VacancyViewProposeOffer/utils/helpers";

const DEFAULT_TIME = new Time(0, 0);

const VacancyViewTimesList = ({ datesItem, nestIndex }) => {
	const user = useGetUser();
	const timezone = get(user, "timezone", TIMEZONE);
	const { fields: times, append, remove } = useFieldArray({
		name: `dates.${nestIndex}.times`
	});

	const {
		formState: { errors },
		control,
		trigger
	} = useFormContext();
	let formatter = useDateFormatter({ dateStyle: "long" });
	const [day, month] = formatter
		.format(datesItem.date.toDate(getLocalTimeZone()))
		.split(" ");

	const onChangeTime = onChange => value => {
		onChange(value || DEFAULT_TIME);
		trigger("dates");
	};

	const onAdd = () => {
		const timeSlot = getTimeSlot({
			timezone,
			workDays: get(user, "general_settings.work_days", []),
			date: datesItem.date
		});
		append(timeSlot);
		trigger("dates");
	};

	const onRemove = index => {
		remove(index);
		trigger("dates");
	};
	return (
		<div className={styles.slotItem}>
			<div className={styles.slotDateValue}>
				{month} {day}
			</div>
			{times.length === 0 ? (
				<span className={styles.emptyState}>
					Can’t find the best times try another Dates.
				</span>
			) : (
				times.map((time, index) => (
					<React.Fragment key={time.id}>
						<div className={styles.row}>
							<Controller
								name={`dates.${nestIndex}.times.${index}.startTime`}
								control={control}
								render={({ field: { value, onChange } }) => (
									<NewTimePicker
										inputClassName={styles.timeInput}
										onChange={onChangeTime(onChange)}
										showIcon={false}
										value={value}
										shouldForceLeadingZeros
									/>
								)}
							/>
							<span className={styles.timeSlotSeparator}>-</span>
							<Controller
								name={`dates.${nestIndex}.times.${index}.endTime`}
								control={control}
								render={({ field: { value, onChange } }) => (
									<NewTimePicker
										inputClassName={styles.timeInput}
										onChange={onChangeTime(onChange)}
										showIcon={false}
										value={value}
										shouldForceLeadingZeros
									/>
								)}
							/>
							{index === 0 ? (
								<button onClick={onAdd} className={styles.addTimeSlotBtn}>
									<PlusIcon />
								</button>
							) : (
								<button
									onClick={() => onRemove(index)}
									className={cx(styles.addTimeSlotBtn, styles.borderless)}
								>
									<CloseIcon className={styles.closeIcon} />
								</button>
							)}
						</div>
						{errors.dates && (
							<FieldError
								className={styles.error}
								error={get(
									errors,
									`dates[${nestIndex}].times[${index}].startTime`
								)}
							/>
						)}
					</React.Fragment>
				))
			)}
		</div>
	);
};

export default VacancyViewTimesList;
