import { SEARCH_ADDRESS, COMPANY_ADDRESS, PHONE_SCREEN } from "config";
import {
	FILTER_SETTINGS_ENDPOINT,
	GET_AUTHENTICATION_DETAILS
} from "config/api-endpoints";
import { client } from "lib/api-client";
import flatten from "lodash/flatten";
import _get from "lodash/get";
import { LOCATION_TYPES } from "./constants";
import { EMPTY_EMAIL } from "common/MessagingToolNewEmail/utils/constant";
import { v4 as uuid } from "uuid";
import {
	convertDateAndTimeUsingTimezone,
	timestampToDateCalendar
} from "config/helpers";
import { isSameDay, Time } from "@internationalized/date";
import isEmpty from "lodash/isEmpty";
import { TIMEZONE } from "config";

export const getAttendees = (connectedUser, vacancyId) => {
	return new Promise((resolve, reject) => {
		client(FILTER_SETTINGS_ENDPOINT, {
			body: {
				tag: "temporary_user_attendees",
				job_id: vacancyId
			}
		})
			.then(res => {
				const rest = res.list_users.map(({ _id, user_name, avatar }) => ({
					id: _id,
					label: user_name,
					avatar
				}));

				const user = {
					id: connectedUser.id,
					label: `${connectedUser.first_name} ${connectedUser.last_name}`,
					avatar: connectedUser.avatar
				};

				resolve([{ id: 1, label: "Collaborators", children: [user, ...rest] }]);
			})
			.catch(e => reject(e));
	});
};

export const getCalendarEventAttendees = (
	connectedUser,
	vacancyId,
	workflowId
) => {
	if (workflowId || !vacancyId) {
		return [];
	}
	return new Promise((resolve, reject) => {
		client(GET_AUTHENTICATION_DETAILS, {
			body: {
				filter: "calendar_event_attendees",
				keyword: "",
				limit: 10,
				offset: 0,
				vacancy_id: vacancyId
			}
		})
			.then(res => {
				const rest = res.map(
					({ _id, full_name, avatar, groups, active_role }) => ({
						id: _id,
						label: full_name,
						avatar,
						groups,
						active_role
					})
				);

				const children = [...rest];
				const user = {
					id: connectedUser.id,
					label: `${connectedUser.first_name} ${connectedUser.last_name}`,
					avatar: connectedUser.avatar
				};
				const alreadyExist = children.find(
					item => item.id === connectedUser.id
				);
				if (!alreadyExist) children.push(user);
				resolve([{ id: 1, label: "Collaborators", children }]);
			})
			.catch(e => reject(e));
	});
};
export const composeAddress = addressObj => {
	return `${_get(addressObj, "street", "")} ${_get(addressObj, "number", "")}${
		addressObj.number && addressObj.box ? " / " : " "
	}${_get(addressObj, "box", "")} ${_get(addressObj, "zip", "")} ${
		_get(addressObj, "city") ? `${_get(addressObj, "city")},` : ""
	} ${_get(addressObj, "country", "")}`
		.replace(/\s\s+/g, " ")
		.replace(/\s,+/g, ", ");
};

export const isTimeValid = (startTime, endTime) => {
	if (startTime && endTime) {
		return endTime.hour === startTime.hour
			? endTime.minute > startTime.minute
			: endTime.hour > startTime.hour;
	}
	return false;
};

function prepareAvailabilitySlotsPayload(dates, timezone) {
	const availabilitySlots = [];
	dates.forEach(entry => {
		const { year, month, day } = entry.date;
		entry.times.forEach(time => {
			const formattedStartTime = convertDateAndTimeUsingTimezone(
				{
					year,
					month,
					day,
					hour: time.startTime.hour,
					minute: time.startTime.minute
				},
				timezone
			);

			const formattedEndTime = convertDateAndTimeUsingTimezone(
				{
					year,
					month,
					day,
					hour: time.endTime.hour,
					minute: time.endTime.minute
				},
				timezone
			);

			availabilitySlots.push({
				start_date: formattedStartTime.utc().unix(),
				end_date: formattedEndTime.utc().unix()
			});
		});
	});
	return availabilitySlots;
}

export const prepareProposeInterviewPayload = ({
	formData,
	logEvent,
	companyType,
	applicationId,
	interviewId,
	isDeclineInterview,
	timezone
}) => {
	const availabilitySlots = prepareAvailabilitySlotsPayload(
		formData.dates,
		timezone
	);

	const attendeesIds = flatten(
		formData.attendees.map(({ children }) => children.map(({ id }) => id))
	);
	const locationPayload = [SEARCH_ADDRESS, COMPANY_ADDRESS].includes(
		formData.locationType
	)
		? formData.locationValue
		: {};

	const payload = {
		id: formData.id,
		description: formData.note,
		attendees_users: attendeesIds,
		location_type: formData.locationType,
		...locationPayload,
		application_id: applicationId,
		recipient_type: companyType,
		log_event: logEvent,
		availability_slots: availabilitySlots,
		subject: formData.subject,
		content: formData.content,
		content_placeholders: formData.content_placeholders.map(placeholder => ({
			key: placeholder.value,
			value:
				placeholder.value === "INTERVIEW_LINK" && !placeholder._id
					? isDeclineInterview
						? interviewId
						: formData.id
					: placeholder._id || ""
		})),
		attachments: [],
		...(interviewId && {
			interview_id: interviewId
		})
	};
	return payload;
};

export const preparePhoneScreenPayload = ({
	formData,
	logEvent,
	companyType,
	applicationId,
	interviewId,
	isDeclineInterview,
	timezone = TIMEZONE
}) => {
	const availabilitySlots = prepareAvailabilitySlotsPayload(
		formData.dates,
		timezone
	);
	const attendeesIds = flatten(
		formData.attendees.map(({ children }) => children.map(({ id }) => id))
	);

	const payload = {
		id: formData.id,
		description: formData.note,
		attendees_users: attendeesIds,
		location_type: PHONE_SCREEN,
		application_id: applicationId,
		recipient_type: companyType,
		phone: `${formData.phone.countryCode.toUpperCase()} ${
			formData.phone.value
		}`,
		log_event: logEvent,
		availability_slots: availabilitySlots,
		subject: formData.subject,
		content: formData.content,
		content_placeholders: formData.content_placeholders.map(placeholder => ({
			key: placeholder.value,
			value:
				placeholder.value === "INTERVIEW_LINK" && !placeholder._id
					? isDeclineInterview
						? interviewId
						: formData.id
					: placeholder._id || ""
		})),
		attachments: [],
		...(interviewId && {
			interview_id: interviewId
		})
	};
	return payload;
};

export const formatAvailabilitySlots = (availability_slots, timezone) => {
	const dates = [];

	availability_slots?.forEach(slot => {
		const today = window.moment.tz(timezone);
		const start = window.moment.unix(slot.start_date).tz(timezone);
		const end = window.moment.unix(slot.end_date).tz(timezone);

		if (end.unix() < today.unix()) return;

		const date = timestampToDateCalendar(slot.start_date, timezone);
		const startTime = new Time(start.get("hours"), start.get("minutes"));
		const endTime = new Time(end.get("hours"), end.get("minutes"));

		let existing = dates.find(entry => isSameDay(entry.date, date));

		if (!existing) {
			existing = { date, times: [] };
			dates.push(existing);
		}

		existing.times.push({ startTime, endTime });
	});

	return dates;
};

export const getDefaultValues = ({
	interview,
	job,
	connectedUser,
	candidate
}) => {
	const timezone = connectedUser.timezone;

	const dates = formatAvailabilitySlots(
		interview?.availability_slots,
		timezone
	);

	return {
		id: uuid(),
		locationType:
			interview?.location_type || LOCATION_TYPES.companyAddress.value,
		locationValue: interview
			? {
					street: interview.street || undefined,
					number: interview.number || undefined,
					box: interview.box || undefined,
					city: interview.city || undefined,
					zip: interview.zip || undefined,
					country: interview.country || undefined,
					longitude: interview.longitude || undefined,
					latitude: interview.latitude || undefined,
					iso_country: interview.iso_country || undefined,
					viewport: !isEmpty(interview.viewport) ? interview.viewport : []
			  }
			: "",
		dates: dates || [],
		vacancy: job ? { value: job._id, label: job.title } : null,
		candidate: candidate._id ? candidate : null,
		note: interview?.description || "",
		attendees: [
			{
				id: 1,
				label: "Collaborators",
				children: interview?.attendees_users
					? interview?.attendees_users.map(user => ({
							id: user._id,
							label: `${user.first_name} ${user.last_name}`,
							avatar: user.avatar
					  }))
					: [
							{
								id: connectedUser.id,
								label: `${connectedUser.first_name} ${connectedUser.last_name}`
							}
					  ]
			}
		],
		...EMPTY_EMAIL,
		to: candidate ? [candidate] : []
	};
};

export function allPlaceholdersAreValid(placeholders) {
	let placeholdersAreValid = true;
	if (placeholders.length === 0) placeholdersAreValid = false;
	for (let i = 0; i < placeholders.length; i++) {
		if (!placeholders[i].entity) {
			placeholdersAreValid = false;
			break;
		}
	}
	return placeholdersAreValid;
}
