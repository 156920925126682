import React from "react";
import PlannedInterviewsUpcoming from "./PlannedInterviewsUpcoming/PlannedInterviewsUpcoming";
import PlannedInterviewsHistory from "./PlannedInterviewsHistory/PlannedInterviewsHistory";
import styles from "./vacancy-view-planned-interviews.module.scss";
import { TIMEZONE } from "config";

export default function VacancyViewPlannedInterviews({
	job = {},
	timezone = TIMEZONE,
	isPokeDataLoading
}) {
	return (
		<div className={styles.container}>
			<PlannedInterviewsUpcoming
				timezone={timezone}
				isPokeDataLoading={isPokeDataLoading}
			/>
			<PlannedInterviewsHistory
				job={job}
				timezone={timezone}
				isPokeDataLoading={isPokeDataLoading}
			/>
		</div>
	);
}
