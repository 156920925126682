import React, { useState, useEffect, useMemo } from "react";
import { queryCache } from "react-query";
import isEmpty from "lodash/isEmpty";
import cx from "classnames";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import _get from "lodash/get";
import toast from "react-hot-toast";
import style from "../../my-company.module.scss";
import { ReactComponent as IconPen } from "static/icons/iconPen.svg";
import { ReactComponent as AddIcon } from "static/icons/PlusAdd.svg";

import AddressBoxModal from "common/AddressBoxNext/AddressBoxModal";
import RenderInputAdress from "../RenderInputAdress";
import { ReactComponent as LocationIcon } from "static/icons/location.svg";
import { SaveButton } from "common/SaveButton";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { useTour } from "@reactour/tour";
import { SELECTORS_TOUR_SETUP_COMPANY } from "modules/user-onboarding/workflows/setup-company";
import {
	INTERACTIVE_CONTAINER_STYLES,
	TOUR_SETUP_COMPANY
} from "config/onboarding";
import { browserHistory } from "react-router";
import { useOnboardingSaveData } from "modules/user-onboarding/hooks/useOnboardingSaveData";
import { GET_USER_OFFICES_QUERY } from "modules/vacancy/components/vacancyView/hooks/useGetOffices";

const useTourData = () => {
	const { setIsOpen, setSteps, currentStep, isOpen } = useTour();
	const {
		metaData,
		workflowId,
		cleanStore,
		setMetaData,
		setIsLoading,
		activeOverlay,
		setActiveOverlay
	} = useOnboardingStore();

	const isActiveSetupCompanyTour = useMemo(
		() => workflowId === TOUR_SETUP_COMPANY,
		[workflowId]
	);

	const closeTour = () => {
		if (isActiveSetupCompanyTour) {
			setIsOpen(false);
			setActiveOverlay(true);
		}
	};

	// last step close tour
	const onLastStepClose = () => {
		setIsOpen(false); // close tour
		setSteps([]); // clean steps
		if (metaData?.showStartRecrutmentModal) {
			setMetaData({ ...metaData, showModal: true });
		} else {
			cleanStore(); // clean onboarding store
			browserHistory.push("/settings/setup-guide");
		}
	};
	// persist onboarding data
	const { persistData } = useOnboardingSaveData({
		onTourClose: onLastStepClose
	});

	const goLastTourStep = () => {
		if (isActiveSetupCompanyTour) {
			setActiveOverlay(false);
			setIsLoading(true);
			persistData();
		}
	};

	return {
		closeTour,
		goLastTourStep,
		toasterDuration: isActiveSetupCompanyTour ? 500 : false,
		selectors: {
			five: SELECTORS_TOUR_SETUP_COMPANY[4]
		},
		selectorsClassNames: {
			five:
				isActiveSetupCompanyTour && isOpen && currentStep === 4
					? " highlighted"
					: ""
		},
		interactiveContainerStyles:
			isActiveSetupCompanyTour && activeOverlay && currentStep === 4
				? INTERACTIVE_CONTAINER_STYLES
				: {}
	};
};

const Offices = ({
	isLoadingUpdate,
	company,
	updateCompanyInfo,
	hasMultiSite,
	currentSite,
	setActiveTab,
	IsAdmin
}) => {
	const {
		selectors,
		closeTour,
		goLastTourStep,
		toasterDuration,
		selectorsClassNames,
		interactiveContainerStyles
	} = useTourData();

	const [modeEdit, setModeEdit] = useState(false);
	const [editAddress, setEditAddress] = useState(false);
	const [addressIndex, setAddressIndex] = useState(null);
	const { control, watch, handleSubmit, reset, formState } = useForm();
	const { offices } = company;

	const { fields, append, remove } = useFieldArray({
		control,
		name: "items"
	});

	const allField = watch();

	useEffect(() => {
		setModeEdit(false);

		const items = (offices || []).map(office => ({
			name: office
		}));

		reset({
			items
		});
	}, [offices]);

	const getViewport = value => {
		if (value) {
			return {
				northeast: {
					lat: value.getNorthEast().lat(),
					lng: value.getNorthEast().lng()
				},
				southwest: {
					lat: value.getSouthWest().lat(),
					lng: value.getSouthWest().lng()
				}
			};
		} else {
			return null;
		}
	};

	const saveData = data => {
		const payload = {
			tag: "offices",
			offices: _get(data, "items", [])
				.map(e => e.name)
				.map(e => ({
					number: _get(e, "number"),
					street: _get(e, "street"),
					box: _get(e, "box"),
					latitude: _get(e, "latitude"),
					longitude: _get(e, "longitude"),
					city: _get(e, "city"),
					country: _get(e, "country"),
					zip: _get(e, "zip"),
					iso_country: _get(e, "iso_country"),
					viewport:
						getViewport(_get(e, "geometry.viewport")) || _get(e, "viewport")
				})),
			...(hasMultiSite && { site_id: _get(currentSite, "_id") })
		};
		updateCompanyInfo(payload, {
			onSuccess: () => {
				queryCache.invalidateQueries("myCurrentCompany");
				queryCache.invalidateQueries("@getSites");
				queryCache.invalidateQueries(GET_USER_OFFICES_QUERY);
				setActiveTab(_get(company, "_id"));
				setModeEdit(!modeEdit);
				toast.success(`Company details updated.`, {
					position: "bottom-right",
					duration: toasterDuration || 2500
				});
				setTimeout(goLastTourStep, 550);
			},
			onError: () => {
				toast.error(`Error while saving changes`, {
					position: "bottom-right",
					duration: 2500,
					isFailed: true
				});
			}
		});
	};

	const onSubmit = data => {
		saveData(data);
	};

	const handleCancel = () => {
		reset();
		setModeEdit(!modeEdit);
	};

	const { dirtyFields } = formState;

	const isFormValid = _get(allField, "items", []).some(item => !item.name);
	const isSaveButtonDisabled =
		isLoadingUpdate || isEmpty(dirtyFields) || isFormValid;

	return (
		<>
			<div className={style.headerBlock}>
				<h3>Offices</h3>
				{modeEdit ? (
					<div className={style.ctaModeEdit} style={interactiveContainerStyles}>
						<button
							className={style.cancelBtn}
							type="button"
							onClick={() => {
								handleCancel();
								goLastTourStep();
							}}
						>
							Cancel
						</button>

						<SaveButton
							onClick={handleSubmit(onSubmit)}
							isLoading={isLoadingUpdate}
							isDisabled={isSaveButtonDisabled}
						/>
					</div>
				) : (
					IsAdmin && (
						<button
							type="button"
							id={selectors.five}
							onClick={() => {
								closeTour();
								setModeEdit(!modeEdit);
							}}
							disabled={isLoadingUpdate}
							className={selectorsClassNames.five}
						>
							<IconPen /> Edit
						</button>
					)
				)}
			</div>
			<div className={style.card} style={interactiveContainerStyles}>
				<form className={style.formFields} onSubmit={handleSubmit(onSubmit)}>
					{fields.length > 0
						? fields.map((item, index) => (
								<div
									className={cx(style.field, style.fieldOffices)}
									key={item.id}
								>
									{!modeEdit && <LocationIcon />}
									<div
										className={cx(style.infoLabel, style.fullWidth, {
											[style.editMode]: modeEdit
										})}
									>
										<RenderInputAdress
											value={_get(allField, `items[${index}].name`)}
											modeEdit={modeEdit}
											setEditAddress={setEditAddress}
											removeInput={() => remove(index)}
											setAddressIndex={() => setAddressIndex(index)}
										/>
									</div>
									{addressIndex === index && (
										<Controller
											name={`items[${index}].name`}
											control={control}
											render={({ field: { onChange, value } }) => {
												return (
													<AddressBoxModal
														active={editAddress}
														onClose={() => setEditAddress(false)}
														onSave={loc => {
															setEditAddress(false);
															onChange(loc);
														}}
														requiredFields={[
															"street",
															"number",
															"city",
															"zip",
															"country"
														]}
														isRequired={true}
														title="Office address"
														label="Company address"
														currentAddress={value}
													/>
												);
											}}
										/>
									)}
								</div>
						  ))
						: !modeEdit && <div className={style.noOffices}>No offices</div>}

					{modeEdit && (
						<button
							className={style.addBtn}
							type="button"
							onClick={() => append({ name: "" })}
						>
							<AddIcon />
						</button>
					)}
				</form>
			</div>
		</>
	);
};

export default Offices;
