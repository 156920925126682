import React, { useRef, useState } from "react";
import classes from "modules/SearchCandidate/components/TableContainer/table-container.module.scss";
import Portal from "common/Portal";
import s from "common/AgTable/components/MenuColumn/menu-column.module.scss";
import useOnClickOutside from "hooks/useOnClickOutside";
import DotHorzIcons from "common/AgTable/icons/DotsHor";
import missionStyle from "./mission-list-select-column.module.scss";
import Menu from "common/AgTable/components/Menu/Menu";
import get from "lodash/get";
import { Item } from "@react-stately/collections";
import { usePopper } from "react-popper";
import { useToggle } from "react-use";
import { ReactComponent as ExtendIcon } from "static/icons/extend-mission.svg";
import { ReactComponent as InterruptIcon } from "static/icons/interrupt-mission.svg";
import cx from "classnames";
import loadable from "loadable-components";
import { dateToTimestamp, timestampToDateCalendar } from "config/helpers";
import { getLocalTimeZone, today } from "@internationalized/date";
import { _getInterruptedStartDate } from "modules/MissionsDetails/utils/_helper";
import { useSelector } from "react-redux";
import { FEATURE_EXTEND_MISSION } from "config";
import { FEATURE_INTERRUPT_MISSION } from "config";
import { DELETE, EDIT } from "modules/MissionsList/utils/constant";

const ExtendMissionDrawer = loadable(() =>
	import(
		"modules/MissionsList/components/ExtendMissionDrawer/ExtendMissionDrawer"
	)
);
const MissionInterruptDrawer = loadable(() =>
	import(
		"modules/MissionsDetails/components/MissionInterruptDrawer/MissionInterruptDrawer"
	)
);

const MissionsListSelectColumn = ({ data }) => {
	const features = useSelector(state => state.auth?.user?.features);
	const canUserExtendMission = features.includes(FEATURE_EXTEND_MISSION);
	const canUserInterruptMission = features.includes(FEATURE_INTERRUPT_MISSION);

	const [showMenu, setShowMenu] = useToggle(false);
	const [showInterruptionDrawer, setShowInterruptionDrawer] = useState(false);
	const [openExtensionDrawer, setOpenExtensionDrawer] = useState(false);
	const referenceElement = useRef();
	const popperElement = useRef();
	const { item } = data;
	const isInterruprionDisabled = item.interrupt;
	const currentDay = today(getLocalTimeZone());
	const isMissionFinished = item.status === "finished";
	const isStartDateInFuture =
		timestampToDateCalendar(item.start_date) > currentDay;

	const isInterrupted = item.status === "interrupted" || item.interrupt !== "";

	const interrupteDate = _getInterruptedStartDate(
		item.reporting_settings.timesheet_frequency,
		dateToTimestamp(`${currentDay.day}-${currentDay.month}-${currentDay.year}`)
	);

	const isInterruptDisabled = interrupteDate / 1000 > item.end_date;

	const isExtensionEnabled = isMissionFinished;

	const isInterruptDisable =
		isInterrupted ||
		isMissionFinished ||
		isStartDateInFuture ||
		isInterruptDisabled;

	const { styles, attributes } = usePopper(
		referenceElement.current,
		popperElement.current,
		{
			placement: "right-start",
			modifiers: [
				{
					name: "offset",
					options: {
						offset: [-9, 10]
					}
				},
				{
					name: "flip",
					options: {
						fallbackPlacements: ["top", "right"]
					}
				}
			]
		}
	);

	const actionButtons = [];

	if (canUserExtendMission) {
		actionButtons.push({
			name: "Extend mission",
			key: EDIT,
			icon: <ExtendIcon />
		});
	}

	if (canUserInterruptMission) {
		actionButtons.push({
			name: "Interrupt mission",
			key: DELETE,
			icon: <InterruptIcon />
		});
	}

	useOnClickOutside(popperElement, () => {
		setShowMenu(false);
	});

	const handleClickMenu = data => {
		switch (data) {
			case DELETE:
				return !isInterruptDisable && setShowInterruptionDrawer(true);
			case EDIT:
				return isExtensionEnabled && setOpenExtensionDrawer(true);
		}
	};

	return actionButtons.length === 0 ? null : (
		<div className={`${classes.selectIconsContainer}`}>
			{!isInterruprionDisabled ? (
				<button
					className={classes.dotsHorizontal}
					ref={referenceElement}
					onClick={setShowMenu}
				>
					<DotHorzIcons
						width="1.125rem"
						height="1.125rem"
						color="#A3A3A3"
						stroke="1.5"
					/>
				</button>
			) : null}
			<Portal>
				<div
					style={{ ...styles.popper, display: showMenu ? "block" : "none" }}
					{...attributes.popper}
					ref={popperElement}
					className={cx(
						missionStyle.selectColumnsContainer,
						classes.popperContainer
					)}
				>
					<Menu aria-label="Actions" onAction={handleClickMenu}>
						{actionButtons.map(item => {
							const key = get(item, "key");
							return (
								<Item key={key}>
									<div
										className={cx(missionStyle.Item, {
											[missionStyle.notAllowed]:
												(key === EDIT && !isExtensionEnabled) ||
												(key === DELETE && isInterruptDisable)
										})}
										style={{ display: "flex" }}
									>
										{get(item, "icon")}
										<div
											className={s.headerItem}
											data-onboarding-step={item["data-onboarding-step"]}
											style={{ display: "flex", alignItems: "center" }}
										>
											{get(item, "name")}
										</div>
									</div>
								</Item>
							);
						})}
					</Menu>
				</div>
			</Portal>

			{showInterruptionDrawer && (
				<MissionInterruptDrawer
					isOpen={showInterruptionDrawer}
					onClose={() => setShowInterruptionDrawer(false)}
					onSuccess={() => setShowInterruptionDrawer(false)}
					missionId={data?.id}
					interrupteDate={interrupteDate}
				/>
			)}

			<ExtendMissionDrawer
				isOpen={openExtensionDrawer}
				closeDrawer={() => setOpenExtensionDrawer(false)}
				data={data.item}
			/>
		</div>
	);
};

export default MissionsListSelectColumn;
