import React, { useCallback, useMemo } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import styles from "./calendar-working-days.module.scss";
import { DAYS_OF_WEEK } from "modules/calendar-settings/utils/constant";
import cx from "classnames";
import { Time } from "@internationalized/date";
import CalendarWorkingDayTimeSlot from "../calendar-working-day-timeslot/calendar-working-day-timeslot";
import LoadingState from "./loading-state";
import { ReactComponent as CopyIcon } from "static/icons/copy-2.svg";
import { Button } from "common/Button";
import { generateId } from "config/helpers";
import Checkbox from "common/AgTable/components/Checkbox/Checkbox";

const CalendarWorkingDays = ({ isLoading, watch }) => {
	const { control } = useFormContext();
	const { fields: days, remove, update, append, replace } = useFieldArray({
		control,
		name: "work_days"
	});

	const isDaySelected = useCallback(
		day => days.find(item => item.day === day),
		[days]
	);

	const onSelectDay = day => {
		const exists = days.findIndex(item => item.day === day.name);
		if (exists > -1) remove(exists);
		else {
			append({
				day: day.name,
				time_slots: [
					{
						id: generateId(),
						start_time: new Time(9, 0),
						end_time: new Time(17, 0)
					}
				]
			});
		}
	};

	// Sort by the order in DAYS_OF_WEEK
	const sortedDays = useMemo(() => {
		return [...days].sort(
			(a, b) =>
				DAYS_OF_WEEK.findIndex(day => day.name === a.day) -
				DAYS_OF_WEEK.findIndex(day => day.name === b.day)
		);
	}, [days]);

	const copyToAll = () => {
		replace(
			days.map(el => {
				return {
					...el,
					time_slots: sortedDays[0].time_slots
				};
			})
		);
	};

	if (isLoading) return <LoadingState />;

	return (
		<div className={styles.content}>
			<div className={styles.section}>
				<div className={styles.title}>Enable working hours</div>
				<Controller
					name="isWorkingHours"
					control={control}
					render={({ field: { value, onChange } }) => {
						return (
							<div className={styles.checkContainer}>
								<Checkbox
									className={styles.check}
									checked={value}
									height={"22px"}
									width={"22px"}
									onChange={() => {
										onChange(!value);
									}}
								/>

								<label className={styles.text} htmlFor="enableWorkingHours">
									Working hours will help you optimize scheduling for interviews
									and events.
								</label>
							</div>
						);
					}}
				/>
			</div>
			<div className={styles.workingDays}>
				{DAYS_OF_WEEK.map((day, index) => (
					<div
						key={day.name}
						className={cx(styles.shortNameDay, {
							[styles.selected]: isDaySelected(day.name)
						})}
						onClick={() => onSelectDay(day, index)}
					>
						{day.shortname}
					</div>
				))}
			</div>

			<div className={styles.timeSlots}>
				{watch("isWorkingHours") &&
					sortedDays.map((item, index) => (
						<div key={item.id} className={styles.field}>
							<span className={styles.day}>{item.day}</span>

							<CalendarWorkingDayTimeSlot
								nestedIndex={days.findIndex(el => el.day === item.day)}
								day={item}
								updateDay={update}
							/>
							{index === 0 && sortedDays.length > 1 && (
								<Button
									onClick={copyToAll}
									type="button"
									icon={<CopyIcon />}
									text="Copy to all"
									variant="text"
									className={styles.copyLabel}
								/>
							)}
						</div>
					))}
			</div>
		</div>
	);
};

export default CalendarWorkingDays;
