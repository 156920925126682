import React, { useEffect, useRef, useState } from "react";
import globalStyles from "../styles.module.scss";
import { Box } from "rebass/styled-components";
import styles from "./timezone-settings.module.scss";
import Loader from "common/Loader";
import cx from "classnames";
import { ComboBox } from "common/ComboBox";
import { Item } from "react-stately";
import { useGetUser } from "hooks/useGetUser";
import useUpdateTimezone from "./api/use-update-timezone";
import { formatTimezones, renderError } from "config/helpers";
import useGetTimezoneList from "modules/calendar-settings/hooks/api/use-get-timezone-list";

const TimezoneSettings = () => {
	const user = useGetUser();
	const ref = useRef();
	const timezoneUpdated = useRef(false);
	const [timezone, setTimezone] = useState({
		label: user.timezone,
		value: user.timezone
	});
	const [updateTimezone, { isLoading }] = useUpdateTimezone({
		onError: err => {
			setTimezone(timezone);
			renderError(err);
		}
	});

	const [timezones, setTimezones] = useState([]);
	const { data } = useGetTimezoneList();

	useEffect(() => {
		if (!data) return;

		const formattedTimezones = formatTimezones(data);
		setTimezones(formattedTimezones);
		const timezone = formattedTimezones.find(
			item => item.value === user.timezone
		);
		if (timezone) setTimezone(timezone);
	}, [data]);

	useEffect(() => {
		if (timezone.value && timezoneUpdated.current)
			updateTimezone({ timezone: timezone.value });
	}, [timezone.value]);

	const onSelectionTimezoneChange = key => {
		const value = {
			label: timezones.find(item => item.value === key)?.label || "",
			value: key
		};
		setTimezone(value);
		timezoneUpdated.current = true;
	};

	const onInputChange = value => {
		const valueObject = {
			label: value,
			value: value === "" ? null : timezone.value
		};
		setTimezone(valueObject);
	};

	return (
		<div
			className={globalStyles.white_box}
			style={{ marginBottom: "32px", position: "relative" }}
			ref={ref}
		>
			{isLoading && (
				<Box sx={{ position: "absolute", top: "30px", right: "30px" }}>
					<Loader check={false} classes={styles.timezone__loader} />
				</Box>
			)}
			<div className={cx(globalStyles.white_box__title, styles.title)}>
				Timezone
			</div>
			<span className={styles.description}>Select your preferred timezone</span>
			<div className={styles.timezone__settings}>
				<div className={styles.timezone__leftColumn}>
					<ComboBox
						popoverContainer={ref.current}
						inputRootClassName={styles.combobox}
						inputValue={timezone.label}
						selectedKey={timezone.value}
						placeholder={"Select a timezone"}
						onChange={setTimezone}
						onInputChange={onInputChange}
						onSelectionChange={onSelectionTimezoneChange}
					>
						{timezones.map(option => {
							return <Item key={option.value}>{option.label}</Item>;
						})}
					</ComboBox>
				</div>
			</div>
		</div>
	);
};

export default TimezoneSettings;
