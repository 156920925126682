import React, { useState } from "react";
import { queryCache } from "react-query";
import { Controller } from "react-hook-form";
import toast from "react-hot-toast";
import isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _omit from "lodash/omit";
import style from "../../my-company.module.scss";
import { ReactComponent as IconPen } from "static/icons/iconPen.svg";
import { Picker } from "common/Picker";
import { useDispatch } from "react-redux";
import { SaveButton } from "common/SaveButton";
import { getMyProfile } from "modules/user/actions/authActions";
import {
	formatdata,
	formatDataToSend,
	getFieldValues
} from "modules/MyCompany/utils/helper";
import useCompanyCultureForm from "modules/MyCompany/hooks/useCompanyCultureForm";
import CompanyCultureCard from "../CompanyCultureCard/CompanyCultureCard";

const CompanyCulture = ({
	hasMultiSite,
	company,
	currentSite,
	updateCompanyInfo,
	isLoadingUpdate,
	setActiveTab,
	IsAdmin
}) => {
	const dispatch = useDispatch();

	const [modeEdit, setModeEdit] = useState(false);

	const { control, handleSubmit, reset, formState } = useCompanyCultureForm();

	const { dirtyFields, isValid, isDirty } = formState;

	const saveData = data => {
		const payload = {
			tag: "cultures",
			cultures: {
				employee_development: formatDataToSend(
					_get(data, "employeeDevelopment[0].children", [])
				),
				work_life_balance: formatDataToSend(
					_get(data, "workLifeBalance[0].children", [])
				),
				cultures_and_values: formatDataToSend(
					_get(data, "culturesAndValues[0].children", [])
				),
				company_environment: formatDataToSend(
					_get(data, "companyEnvironment[0].children", [])
				),
				employee_wellbeing: formatDataToSend(
					_get(data, "employeeWellbeing[0].children", [])
				)
			},
			...(hasMultiSite && { site_id: _get(currentSite, "_id") })
		};

		updateCompanyInfo(hasMultiSite ? payload : _omit(payload, ["site_id"]), {
			onSuccess: () => {
				queryCache.invalidateQueries("myCurrentSite");
				queryCache.invalidateQueries("@getSites");
				setActiveTab(_get(company, "_id"));
				setModeEdit(!modeEdit);
				toast.success(`company details updated.`, {
					position: "bottom-right",
					duration: 2500
				});
				if (currentSite?.is_default) dispatch(getMyProfile());
			},
			onError: error => {
				if (error.status === 422) {
					const message = error.detail.name;
					if (message) {
						toast(message || "Error while saving changes", {
							isFailed: true
						});
					}
				} else {
					toast("Error while saving changes", {
						isFailed: true
					});
				}
			}
		});
	};

	const onFormSubmit = data => {
		saveData(data);
	};

	const onSubmit = () => {
		handleSubmit(onFormSubmit)();
	};

	const getCultureData = (key, label) => {
		const data = _get(company, `cultures.${key}`, []);
		return data.length > 0 ? formatdata(data, label) : [];
	};

	const handleCancel = () => {
		setModeEdit(false);
		reset();
	};

	return (
		<>
			<div className={style.headerBlock}>
				<h3>Company Culture & Values</h3>
				{modeEdit ? (
					<div className={style.ctaModeEdit}>
						<button
							type="button"
							className={style.cancelBtn}
							onClick={() => {
								handleCancel();
							}}
						>
							Cancel
						</button>
						<SaveButton
							onClick={onSubmit}
							isLoading={isLoadingUpdate}
							isDisabled={isLoadingUpdate || isEmpty(dirtyFields) || !isValid}
						/>
					</div>
				) : (
					IsAdmin && (
						<button
							type="button"
							onClick={() => {
								setModeEdit(!modeEdit);
								reset({
									employeeDevelopment: getCultureData(
										"employee_development",
										"Employee development"
									),
									workLifeBalance: getCultureData(
										"work_life_balance",
										"Work Life Balance"
									),
									culturesAndValues: getCultureData(
										"cultures_and_values",
										"Cultures And Values"
									),
									companyEnvironment: getCultureData(
										"company_environment",
										"Company environment"
									),
									employeeWellbeing: getCultureData(
										"employee_wellbeing",
										"Employee wellbeing"
									)
								});
							}}
						>
							<IconPen /> Edit
						</button>
					)
				)}
			</div>
			{!isValid && modeEdit && isDirty && <CompanyCultureCard />}
			<div className={style.card}>
				<form
					className={style.formFields}
					onSubmit={handleSubmit(onFormSubmit)}
				>
					<div className={style.field}>
						<div className={style.label}>Employee development</div>
						<div className={style.infoLabel}>
							{modeEdit ? (
								<Controller
									name="employeeDevelopment"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Picker
											PrimaryHeaderContent={() => "Employee development"}
											SecondaryHeaderContent={() => "selected"}
											displayRating={false}
											isMultipleSelect={true}
											onConfirm={e => {
												onChange(e);
											}}
											onSkillDeleteFromInput={e => {
												onChange(e);
											}}
											value={value}
											queryFn={() => {
												return new Promise((resolve, reject) => {
													getFieldValues({ tag: "employee_development" })
														.then(res => {
															const data =
																res?.employee_development[0]?.options;
															const formattedData = formatdata(
																data,
																"Employee development"
															);
															resolve(formattedData);
														})
														.catch(e => reject(e));
												});
											}}
											queryId={"GET_EMPLOYEE_DEVELOPMENT_LIST"}
											useServerFilter={false}
											inlineChips={true}
											inputClassName={style.industryInput}
										/>
									)}
								/>
							) : (
								<div className={style.chipsContainer}>
									{_get(company, "cultures.employee_development", []).map(c => (
										<span key={c._id} className={style.industriesChip}>
											{c.name}
										</span>
									))}
								</div>
							)}
						</div>
					</div>
					<div className={style.field}>
						<div className={style.label}>Work-Life balance</div>
						<div className={style.infoLabel}>
							{modeEdit ? (
								<Controller
									name="workLifeBalance"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Picker
											PrimaryHeaderContent={() => "work-Life balance"}
											SecondaryHeaderContent={() => "selected"}
											displayRating={false}
											isMultipleSelect={true}
											onConfirm={e => {
												onChange(e);
											}}
											onSkillDeleteFromInput={e => {
												onChange(e);
											}}
											value={value}
											queryFn={() => {
												return new Promise((resolve, reject) => {
													getFieldValues({ tag: "work_life_balance" })
														.then(res => {
															const data = res?.work_life_balance[0]?.options;
															const formattedData = formatdata(
																data,
																"Work Life Balance"
															);
															resolve(formattedData);
														})
														.catch(e => reject(e));
												});
											}}
											queryId={"GET_WORK_LIFE_BALANCE_LIST"}
											useServerFilter={false}
											inlineChips={true}
											inputClassName={style.industryInput}
										/>
									)}
								/>
							) : (
								<div className={style.chipsContainer}>
									{_get(company, "cultures.work_life_balance", []).map(c => (
										<span key={c._id} className={style.industriesChip}>
											{c.name}
										</span>
									))}
								</div>
							)}
						</div>
					</div>
					<div className={style.field}>
						<div className={style.label}>Cultures & Values</div>
						<div className={style.infoLabel}>
							{modeEdit ? (
								<Controller
									name="culturesAndValues"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Picker
											PrimaryHeaderContent={() => "Cultures & Values"}
											SecondaryHeaderContent={() => "selected"}
											displayRating={false}
											isMultipleSelect={true}
											onConfirm={e => {
												onChange(e);
											}}
											onSkillDeleteFromInput={e => {
												onChange(e);
											}}
											value={value}
											queryFn={() => {
												return new Promise((resolve, reject) => {
													getFieldValues({ tag: "cultures_and_values" })
														.then(res => {
															const data = res?.cultures_and_values[0]?.options;
															const formattedData = formatdata(
																data,
																"Cultures And Values"
															);
															resolve(formattedData);
														})
														.catch(e => reject(e));
												});
											}}
											queryId={"GET_CULTURES_AND_VALUES_LIST"}
											useServerFilter={false}
											inlineChips={true}
											inputClassName={style.industryInput}
										/>
									)}
								/>
							) : (
								<div className={style.chipsContainer}>
									{_get(company, "cultures.cultures_and_values", []).map(c => (
										<span key={c._id} className={style.industriesChip}>
											{c.name}
										</span>
									))}
								</div>
							)}
						</div>
					</div>
					<div className={style.field}>
						<div className={style.label}>Company Environment</div>
						<div className={style.infoLabel}>
							{modeEdit ? (
								<Controller
									name="companyEnvironment"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Picker
											PrimaryHeaderContent={() => "Company environment"}
											SecondaryHeaderContent={() => "selected"}
											displayRating={false}
											isMultipleSelect={true}
											onConfirm={e => {
												onChange(e);
											}}
											onSkillDeleteFromInput={e => {
												onChange(e);
											}}
											value={value}
											queryFn={() => {
												return new Promise((resolve, reject) => {
													getFieldValues({ tag: "company_environment" })
														.then(res => {
															const data = res?.company_environment[0]?.options;
															const formattedData = formatdata(
																data,
																"Company environment"
															);
															resolve(formattedData);
														})
														.catch(e => reject(e));
												});
											}}
											queryId={"GET_COMPANY_ENVIRONMENT_LIST"}
											useServerFilter={false}
											inlineChips={true}
											inputClassName={style.industryInput}
										/>
									)}
								/>
							) : (
								<div className={style.chipsContainer}>
									{_get(company, "cultures.company_environment", []).map(c => (
										<span key={c._id} className={style.industriesChip}>
											{c.name}
										</span>
									))}
								</div>
							)}
						</div>
					</div>
					<div className={style.field}>
						<div className={style.label}>Employee wellbeing</div>
						<div className={style.infoLabel}>
							{modeEdit ? (
								<Controller
									name="employeeWellbeing"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Picker
											PrimaryHeaderContent={() => "Employee wellbeing"}
											SecondaryHeaderContent={() => "selected"}
											displayRating={false}
											isMultipleSelect={true}
											onConfirm={e => {
												onChange(e);
											}}
											onSkillDeleteFromInput={e => {
												onChange(e);
											}}
											value={value}
											queryFn={() => {
												return new Promise((resolve, reject) => {
													getFieldValues({ tag: "employee_wellbeing" })
														.then(res => {
															const data = res?.employee_wellbeing[0]?.options;
															const formattedData = formatdata(
																data,
																"Employee wellbeing"
															);
															resolve(formattedData);
														})
														.catch(e => reject(e));
												});
											}}
											queryId={"GET_EMPLOYEE_WELLBEING_LIST"}
											useServerFilter={false}
											inlineChips={true}
											inputClassName={style.industryInput}
										/>
									)}
									employee_development
								/>
							) : (
								<div className={style.chipsContainer}>
									{_get(company, "cultures.employee_wellbeing", []).map(c => (
										<span key={c._id} className={style.industriesChip}>
											{c.name}
										</span>
									))}
								</div>
							)}
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default CompanyCulture;
