import React from "react";
import { TOUR_CALENDAR } from "config/onboarding";
import { ReactComponent as BuildingIcon } from "static/icons/building.svg";
import { ReactComponent as CalendarIcon } from "static/icons/calendar-plus-2.svg";
import { ReactComponent as SettingsIcon } from "static/icons/settings-01.svg";
import { ReactComponent as LeaveIcon } from "static/icons/log-out-02.svg";
import { ReactComponent as MarkerPinIcon } from "static/icons/marker-pin.svg";
import { ReactComponent as InterviewIcon } from "static/icons/microphone-01.svg";
import { ReactComponent as VideoRecorderIcon } from "static/icons/video-recorder.svg";

export const CALENDAR_VIEWS = {
	day: "timeGridDay",
	week: "timeGridWeek",
	month: "dayGridMonth"
};
export const TOUR_GUIDE_FOCUSED_DAY = 1729083600 * 1000;
export const EVENT_STYLE = {
	color: "#334155",
	textColor: "#334155",
	backgroundColor: {
		owner: "#E6F3F2",
		attendee: "#fff"
	},
	borderColor: {
		owner: "#99CECA",
		attendee: "#CBD5E1"
	}
};
export const SYNC_STATUSES = {
	finished: "finished",
	failed: "failed",
	in_progress: "in_progress"
};
export const GROUP_ACTIONS = {
	decline: "decline",
	leave: "leave"
};
export const SCHEDULE_MENU = {
	default: {
		key: "event",
		label: "Event",
		icon: CalendarIcon,
		tour_key: `${TOUR_CALENDAR}-4`
	},
	interview: {
		key: "interview",
		label: "Interview",
		icon: InterviewIcon,
		tour_key: `${TOUR_CALENDAR}-5`
	}
};

export const EVENT_MIN_DURATION = "00:15";
export const TIME_SLOTS_FREQUENCY = "00:30";

export const MAX_VISIBLE_USERS = 5;

export const COLLABORATIVE_GROUP_ACTIONS = {
	settings: {
		key: "settings",
		label: "Settings",
		icon: <SettingsIcon />
	},
	leave_group: {
		key: "leave_group",
		label: "Leave Group",
		icon: <LeaveIcon />
	}
};

export const VIDEO_CONFERENCE = "video_conference";
export const SEARCH_ADDRESS = "search_address";
export const COMPANY_ADDRESS = "company_address";

export const LOCATION_TYPES = {
	companyAddress: {
		label: "Company Address",
		value: COMPANY_ADDRESS,
		icon: <BuildingIcon />
	},
	anotherLocation: {
		label: "Another location",
		value: SEARCH_ADDRESS,
		icon: <MarkerPinIcon />
	},
	online: {
		label: "Online",
		value: VIDEO_CONFERENCE,
		icon: <VideoRecorderIcon />
	}
};

export const ATTENDEES_DEFAULT_LIMIT = 10;
export const CALENDAR_COLUMN_TIME_FORMAT = {
	hour: "2-digit",
	minute: "2-digit",
	hour12: false
};

export const FOCUSED_HOUR_DIFF = -2;
export const SLOT_MIN_DURATION = 15;
export const SCHEDULE_POPOVER_WIDTH = 113;

export const MICROPHONE_TOOLTIP = {
	attendee: "You’re an attendee of this event.",
	organizer: {
		attendee: "You’re the organizer and an attendee of this event.",
		not_attendee: "You’re the organizer but not attending this interview."
	}
};

export const VIRTUAL_ROOM_URL_REGEX = /^\/virtual-room\?displayName=[^&]+&roomUrl=[^&]+$/;

export const statusInterviewConfig = {
	sent: {
		label: "Interview Sent",
		color: "#F59E0B"
	},
	draft: {
		label: "Interview Sent",
		color: "#F59E0B"
	},
	declined: {
		label: "New Interview",
		color: "#04AA46"
	},
	expired: {
		label: "Interview Overdue",
		color: "#DC2626"
	},
	accepted: {
		label: "Interview Planned",
		color: "#6C17D8"
	},
	done: {
		label: "Interview Done",
		color: "#2563EB"
	},
	refused: {
		label: "Interview Canceled",
		color: "#000000",
		borderColor: "#D4D4D4"
	},
	cancelled: {
		label: "Interview Canceled",
		color: "#000000",
		borderColor: "#D4D4D4"
	}
};

export const UTC_ISO_STRING = "YYYY-MM-DDTHH:mm:ss[Z]";
export const WEEKEND = ["Sat", "Sun"];
