import { client } from "lib/api-client";
import { useQuery } from "react-query";
import { MESSAGING_TOOL_FILTER_OPTIONS } from "config/api-endpoints";

const getTimezoneList = () => {
	return client(MESSAGING_TOOL_FILTER_OPTIONS, {
		body: {
			filter: "timezone_list"
		}
	});
};

export const CALENDAR_TIMEZONE_LIST = "CALENDAR_TIMEZONE_LIST";

const useGetTimezoneList = options => {
	return useQuery([CALENDAR_TIMEZONE_LIST], getTimezoneList, {
		refetchOnWindowFocus: false,
		retry: false,
		staleTime: Infinity,
		...options
	});
};

export default useGetTimezoneList;
