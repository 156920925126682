import { NEW_FILTER_SETTINGS } from "config/api-endpoints";
import { client } from "lib/api-client";

export const getFieldValues = ({ tag }) => {
	return client(NEW_FILTER_SETTINGS, {
		body: {
			list: "generic_options",
			tag
		}
	});
};

export const formatdata = (data = [], label = "") => {
	const child = data.map(e => {
		return {
			id: e._id,
			label: e.name
		};
	});

	const parent = [{ id: 1, label: label, children: child }];
	return parent;
};

export const formatDataToSend = (data = []) => {
	const dataToSend = data.map(e => e.id);
	return dataToSend;
};
