import React from "react";
import { isSameDay, today } from "@internationalized/date";
import cx from "classnames";
import { Button } from "common/Button";
import Tooltip from "common/Tippy";
import { generateId, renderError, sortCalendarDates } from "config/helpers";
import { useFieldArray, useFormContext } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as ClockIcon } from "static/icons/clock-1.svg";
import { ReactComponent as StarsIcon } from "static/icons/stars-02.svg";
import { MAX_DAYS_SELECTED } from "../../helper/constants";
import { VacancyViewTimesList } from "../../VacancyViewTimesList";
import useFindBestTimes from "../api/useFindBestTimes";
import CalendarMultipleSelect from "../ProposeInterviewCalendarMultipleSelect/ProposeInterviewCalendarMultipleSelect";
import styles from "./vacancy-view-propose-interview-time-slots.module.scss";
import { useGetUser } from "hooks/useGetUser";
import { formatAvailabilitySlots } from "../../helper/utils";
import {
	formatFindBestTimeSlotsPayload,
	getTimeSlot
} from "../../VacancyViewProposeOffer/utils/helpers";
import get from "lodash/get";

const VacancyViewProposeInterviewTimeSlots = ({
	findBestTimeSlots = false
}) => {
	const user = useGetUser();
	const { control, watch, getValues } = useFormContext();
	const [focusedDate, setFocusedDate] = React.useState(undefined);
	const [isFocused, setIsFocused] = React.useState(false);
	const [findBestTimesEnabled, setFindBestTimesEnabled] = React.useState(false);
	const { fields: datesFields, remove, replace } = useFieldArray({
		control,
		name: "dates"
	});

	const { isLoading: isFindBestTimesLoading } = useFindBestTimes(
		{
			...formatFindBestTimeSlotsPayload(
				getValues("dates"),
				getValues("attendees"),
				user.timezone
			)
		},
		{
			enabled: findBestTimesEnabled && getValues("attendees").length > 0,
			onSuccess: data => {
				setFindBestTimesEnabled(false);
				const newDates = formatAvailabilitySlots(data, user.timezone);
				replace(newDates);
			},
			onError: renderError
		}
	);

	React.useEffect(() => {
		const dates = watch("dates");
		if (dates?.length && !isFocused) {
			setFocusedDate(dates[0].date);
			setIsFocused(true);
		}
	}, [watch("dates"), isFocused]);

	const onDateChange = date => {
		const index = datesFields.findIndex(item => isSameDay(item.date, date));

		if (datesFields.length === MAX_DAYS_SELECTED) return;

		if (index !== -1) {
			remove(index);
		} else {
			const timeSlot = getTimeSlot({
				timezone: user.timezone,
				workDays: get(user, "general_settings.work_days", []),
				date
			});
			const dateField = { id: generateId(), date, times: [timeSlot] };
			const datesResult = [...getValues("dates"), dateField];
			datesResult.sort((a, b) => sortCalendarDates(a.date, b.date));
			replace(datesResult);
		}
	};

	const onFindBestTimes = () => {
		setFindBestTimesEnabled(true);
	};

	const isDateUnavailable = date => {
		return date.compare(today(user.timezone)) < 0;
	};

	return (
		<div className={styles.calendarContainer}>
			<div>
				<CalendarMultipleSelect
					onChange={onDateChange}
					isMultiple
					className={styles.calendar}
					list={datesFields.map(item => item.date)}
					cellClassName={styles.cellClassName}
					isDateUnavailable={isDateUnavailable}
					focusedValue={focusedDate}
					onFocusChange={date => setFocusedDate(date)}
				/>
			</div>

			<div>
				<div className={styles.slotsPicker}>
					<div className={styles.slotsHeader}>
						<ClockIcon />
						<span>Select time</span>
						{findBestTimeSlots && (
							<>
								{datesFields.length === 0 ? (
									<Tooltip
										content="Please select at least one day"
										theme="dark"
									>
										<Button
											variant="outlined"
											text="Find best Times"
											icon={
												<StarsIcon
													className={
														datesFields.length === 0 ? styles.disabledIcon : ""
													}
												/>
											}
											rootClassName={styles.findBestTimesRootButton}
											className={cx(styles.findBestTimesButton, {
												[styles.disabled]: datesFields.length === 0
											})}
											onClick={onFindBestTimes}
										/>
									</Tooltip>
								) : (
									<Button
										variant="outlined"
										text="Find best Times"
										icon={<StarsIcon />}
										rootClassName={styles.findBestTimesRootButton}
										className={styles.findBestTimesButton}
										onClick={onFindBestTimes}
									/>
								)}
							</>
						)}
					</div>
					<div className={styles.timesList}>
						{isFindBestTimesLoading ? (
							<Skeleton height={40} />
						) : (
							<>
								{datesFields.map((item, index) => (
									<VacancyViewTimesList
										datesItem={item}
										key={item.id}
										nestIndex={index}
									/>
								))}
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default VacancyViewProposeInterviewTimeSlots;
