import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { CreateVacancyEditButton } from "../CreateVacancyEditButton";
import { CreateVacancyFieldContainer } from "../CreateVacancyFieldContainer";
import { CreateVacancyFieldLabel } from "../CreateVacancyFieldLabel";
import { CreateVacancyInfoIcon } from "../CreateVacancyInfoIcon";
import { CreateVacancyLabelContainer } from "../CreateVacancyLabelContainer";
import { CreateVacanyAddButton } from "../CreateVacanyAddButton";
import loadable from "loadable-components";
import styles from "./profile-step-languages-field.module.scss";
import CreateVacancyLanguageTag from "../CreateVacancyLanguageTag/CreateVacancyLanguageTag";

const ProfileQueryLanguagesEditorDialog = loadable(() =>
	import("../ProfileQueryLanguagesEditorDialog")
);
const QUERY_LANGUGAE_MODAL = "QUERY_LANGUGAE_MODAL";

const ProfileStepLanguagesField = ({ onSave: onSaveProp }) => {
	const [displayLanguagesModal, setDisplayModalLanguages] = useState("");
	const { watch } = useFormContext();
	const languagesList = watch("query_languages");

	const handleAddLanguagesClick = () =>
		setDisplayModalLanguages(QUERY_LANGUGAE_MODAL);

	const handleLanguagesModalCancelClick = () => setDisplayModalLanguages("");

	const handleLanguagesModalEscapeKeyDown = () => setDisplayModalLanguages("");

	const onSave = list => {
		setDisplayModalLanguages("");
		onSaveProp(list);
	};

	return (
		<CreateVacancyFieldContainer>
			<CreateVacancyLabelContainer>
				<CreateVacancyFieldLabel>Query Languages</CreateVacancyFieldLabel>
				<CreateVacancyInfoIcon>
					Languages are another fundamental part of the matching process.
					Although they are weighted less than skills in the matching score
					calculation, it is still important to properly outline your linguistic
					skills.
				</CreateVacancyInfoIcon>
			</CreateVacancyLabelContainer>
			{!!languagesList?.length && (
				<div className={styles.languagesList}>
					{languagesList.map(e =>
						e.language ? (
							<CreateVacancyLanguageTag label={e.language} key={e.id}>
								{`${e.proficiency ?? 0} / 5`}
							</CreateVacancyLanguageTag>
						) : (
							<div key={e.id} className={styles.languageTag}>
								{e.value}
							</div>
						)
					)}
				</div>
			)}
			{languagesList?.length > 0 ? (
				<CreateVacancyEditButton onClick={handleAddLanguagesClick} />
			) : (
				<CreateVacanyAddButton onClick={handleAddLanguagesClick} />
			)}

			{displayLanguagesModal === QUERY_LANGUGAE_MODAL && (
				<ProfileQueryLanguagesEditorDialog
					onCancel={handleLanguagesModalCancelClick}
					onEscapeKeyDown={handleLanguagesModalEscapeKeyDown}
					onSave={onSave}
					languagesList={languagesList}
				/>
			)}
		</CreateVacancyFieldContainer>
	);
};

export default ProfileStepLanguagesField;
