import {
	addressObjectToString,
	convertCalendarObjectToTimestampUsingTimezone,
	timestampToDateCalendar
} from "config/helpers";
import { EMPTY_EMAIL } from "common/MessagingToolNewEmail/utils/constant";
import {
	EMPLOYMENT_TYPE,
	PAYMENTS_TYPE,
	PAYMENTS_TIME,
	SEARCH_ADDRESS,
	COMPANY_ADDRESS
} from "config";
import _find from "lodash/find";
import _get from "lodash/get";
import { getDayOfWeek, Time } from "@internationalized/date";
import { DAYS_OF_WEEK } from "modules/calendar-settings/utils/constant";
import { ANOTHER_DAY_START_HOUR } from "../../helper/constants";
import flatten from "lodash/flatten";
import { TIMEZONE } from "config";

export const formatAddress = value => {
	return `${_get(value, "street", "") && _get(value, "street") + " "}${_get(
		value,
		"number",
		""
	) && _get(value, "number", "") + " "}${_get(value, "box", "") &&
		_get(value, "box", "") + " "}${_get(value, "zip", "") &&
		_get(value, "zip", "") + " "}${_get(value, "city", "") &&
		_get(value, "city", "") + " "}${_get(value, "country", "")}`;
};

export function formatAddressesList(list) {
	if (!list) return [];
	const listFormatted = list.map(address => {
		const addressString = addressObjectToString(address);

		return {
			label: addressString,
			value: JSON.stringify(address)
		};
	});

	return listFormatted;
}

export const getDefaultValues = ({ offerData, jobData, candidate }) => {
	let custom_address = {};
	const contract_type = _find(EMPLOYMENT_TYPE, [
		"value",
		offerData?.contract_type || jobData?.employment_type
	]);
	const salary_type = _find(PAYMENTS_TYPE, [
		"value",
		offerData?.["payment_type"] || jobData?.["payment_type"]
	]);

	const payment_periode = _find(PAYMENTS_TIME, [
		"value",
		offerData?.["payment_time"] || jobData?.["payment_time"]
	]);

	if (
		offerData?.location_type === SEARCH_ADDRESS ||
		offerData?.location_type === COMPANY_ADDRESS
	)
		custom_address = {
			street: offerData.street,
			number: offerData.number,
			box: offerData.box,
			city: offerData.city,
			zip: offerData.zip,
			country: offerData.country,
			longitude: offerData.longitude,
			latitude: offerData.latitude,
			iso_country: offerData.iso_country,
			viewport: offerData.viewport
		};

	return {
		contract_type: contract_type?.value || "",
		salary_type: salary_type?.value || "",
		payment_periode: payment_periode?.value || "",
		currency: {
			value: offerData?.["currency"] || jobData?.["currency"] || "EUR",
			symbol: offerData?.["symbol"] || jobData?.["symbol"] || "EUR"
		},
		amount: offerData?.amount * 100 || jobData?.salary_range_end,
		extra_benefits:
			offerData?.["other_benefits"] || jobData?.["other_benefits"],
		location_type: offerData?.location_type || COMPANY_ADDRESS,
		start_date: offerData?.start_date
			? timestampToDateCalendar(offerData.start_date)
			: undefined,
		custom_address,
		files: [],
		...EMPTY_EMAIL,
		to: candidate ? [candidate] : []
	};
};

export function getTimeSlot({ date, timezone = TIMEZONE, workDays }) {
	const today = window.moment.tz(timezone);
	const dayOfWeek = getDayOfWeek(date, "en-US"); // en-US because the first day of the week is Sunday,
	const workDayTimeSlots = workDays.find(
		workDay => workDay.day === DAYS_OF_WEEK[dayOfWeek].name
	);
	let startHour = ANOTHER_DAY_START_HOUR;
	const isSameDate =
		today.get("year") === date.year &&
		today.get("month") + 1 === date.month &&
		today.get("date") === date.day;
	const hasTimeSlots = workDayTimeSlots?.time_slots?.length > 0;
	if (isSameDate && hasTimeSlots) {
		const startTime = workDayTimeSlots.time_slots[0].start_time;
		const startTimeHours = window.moment
			.unix(startTime)
			.tz(timezone)
			.get("hours");

		startHour =
			startTimeHours > today.get("hours")
				? startTimeHours
				: today.get("hours") + 1;
	} else if (hasTimeSlots) {
		const startTime = workDayTimeSlots.time_slots[0].start_time;
		startHour = window.moment
			.unix(startTime)
			.tz(timezone)
			.get("hours");
	} else if (isSameDate) {
		startHour = today.get("hours") + 1;
	}
	const timeSlot = {
		startTime: new Time(startHour, 0),
		endTime: new Time(startHour + 1, 0)
	};

	return timeSlot;
}

export function formatFindBestTimeSlotsPayload(dates, attendees, timezone) {
	return {
		selected_dates: dates.map(({ date }) =>
			convertCalendarObjectToTimestampUsingTimezone(date, timezone)
		),
		attendees: flatten(
			attendees.map(({ children }) => children.map(({ id }) => id))
		)
	};
}
